import * as React from 'react'
import { Fabric } from 'office-ui-fabric-react/lib/Fabric'
import { RouteComponentProps, Link } from 'react-router-dom'
import { adalApiFetch, sendRapportError } from '../../adalConfig'
import Header from '../Header'
import Menu from '../Menu'
import History from '../History'

import {
  MessageBar
  , MessageBarType
  , Spinner
  , SpinnerSize
} from '@fluentui/react'
import { initCategory } from './pricingShared'
import PricingReportTypeZero from './pricingReportTypeZero'
import PricingReportTypeOne from './pricingReportTypeOne'

const ErrorNotification = () => (
    <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
    >
        Une erreur est survenue lors de la récupération / mis à jour des données, veuillez réessayer plus tard !
    </MessageBar>
)

interface IPricingConfig {
  menuItems: any[] // Contient la liste des rapports a afficher
  idCategorie: number // Contient l'identifiant de la categorie de la page selectionee
  reportId: string // Contient l'identifiant du rapport power bi a afficher
  rapportItem: any // Contient le rapport power bi selectionne
  workspaceId: string // Contient l'identifiant du worspace du rapport power bi a afficher
  embedUrl: string // Contient l'URL du rapport a afficher
  token: string // Contient le Token d'authentification
  returnMessage: string // Contient le message de retour qui s'affichera en cas de rapport non trouve ou d'erreur
  isLoaded: boolean // Determine si un rapport a ete trouve et recupere
  isError: boolean // Determine si une erreur s'est produite lors de la recuperation des informations
  isAnyReport: boolean // Determine si le rapport avec les identifiants fournis a bien ete trouve
  isPanelOpen: boolean // Determine si le panel du sous menu est ouvert ou pas
  reportType: number
  iframe: any
}

export default class Pricing extends React.Component<RouteComponentProps<{ rapportId?: any }> & { CategorieName: string, path: string, title: string }, IPricingConfig> {
  constructor (props: any) {
    super(props)
    this.state = {
      isError: false,
      idCategorie: -1,
      menuItems: [],
      reportId: '',
      workspaceId: '',
      embedUrl: '',
      token: '',
      rapportItem: '',
      returnMessage: '',
      isLoaded: false,
      isAnyReport: false,
      isPanelOpen: true, // Pour ouvrir le panel du sous menu lors du chargement du composant
      reportType: 0, // powerBi report by default
      iframe: ''
    }
  }

  async componentDidMount () {
    // Récupérer l'identifiant de la catégorie
    this.setState({ returnMessage: 'Veuillez attendre le chargement du rapport ...' })

    await initCategory(
      this.props,
      (cat) => { this.setState({ idCategorie: cat.id }) },
      () => this.setState({ isError: true })
    )

    if (this.state.idCategorie > 0) {
      this.setState({ isAnyReport: false })
      // Récupérer les rapports a afficher dans le sous menu de la page Pricing VL
      const menuItems = localStorage.getItem('reportsMenuItems')
      if (this.props.match.params.rapportId && menuItems) {
        const items = JSON.parse(menuItems)
        this.setState({ menuItems: items, isError: false, isAnyReport: true })
        for (let i = 0; i < items.length; i++) {
          if (items[i].id === this.props.match.params.rapportId) {
            this.aquireToken(items[i])
            break
          }
        }
      } else await this.getAllReport()
    }

    try {
      (() => {
        const closeButton = document.getElementsByClassName('ms-Panel-closeButton')[0]
        closeButton.addEventListener('click', (e: Event) => this.OpenPanel())
      })()
    } catch (error) {
      console.error(error)
    }

    this.setState({ isLoaded: true })
  }

  onSetState (data) {
    this.setState(data)
  }

  async getAllReport () {
    const apiToken = window.localStorage.getItem('apiToken')
    const userId = window.localStorage.getItem('userId')

    await adalApiFetch(fetch, '/GetAuthorizedReports', {
      headers: {
        Authorization: 'Bearer ' + apiToken,
        UtilisateurId: userId,
        CategorieId: this.state.idCategorie,
        'Content-Type': 'application/json'
      },
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ menuItems: responseJson, isError: false })
        localStorage.setItem('reportsMenuItems', JSON.stringify(responseJson))

        if (this.state.menuItems.length > 0) {
          this.setState({ isAnyReport: true })
          if (this.props.match.params.rapportId) {
            for (let i = 0; i < this.state.menuItems.length; i++) {
              if (this.state.menuItems[i].id === this.props.match.params.rapportId) {
                this.aquireToken(this.state.menuItems[i])
                break
              }
            }
          } else {
            if (this.state.menuItems.length > 0) { this.aquireToken(this.state.menuItems.reverse()[0]) }
          }
        } else {
          this.setState({ returnMessage: "Aucun rapport Power BI n'a été trouvé pour cette catégorie !" })
        }
      })
      .catch(error => {
        this.setState({ isError: true })
        console.error(error)
      })
  }

  OpenPanel () {
    this.setState({ isPanelOpen: false })
  }

  async aquireToken (report: any) {
    this.setState({ isPanelOpen: true })
    await this.setState({ isLoaded: false, isError: false })

    await this.setState({ rapportItem: report })

    // new url with report.id
    if ('/' + this.props.path + '/' + report.id !== window.location.pathname) {
      History.push('/' + this.props.path + '/' + report.id)
      return
    }

    if (report.rapportPowerBi) { await this.setState({ reportType: 0 }) } else if (report.rapportMicroStrategy) {
      return
    }

    const apiToken = window.localStorage.getItem('apiToken')
    const userId = window.localStorage.getItem('userId')

    await adalApiFetch(fetch, '/GetToken', {
      headers: {
        Authorization: 'Bearer ' + apiToken,
        UtilisateurId: userId,
        WorkspaceId: report.rapportPowerBi ? report.rapportPowerBi.powerBiWorkspaceId : '',
        PowerBiReportId: report.rapportPowerBi ? report.rapportPowerBi.powerBiReportId : '',
        MicroStrategyReportId: report.rapportMicroStrategy ? report.rapportMicroStrategy.microStrategyReportId : '',
        MicroStrategyFolderId: report.rapportMicroStrategy ? report.rapportMicroStrategy.microStrategyReportId : '',
        MicroStrategyLibraryId: report.rapportMicroStrategy ? report.rapportMicroStrategy.microStrategyReportId : '',
        ReportId: report.id
      },
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ returnMessage: 'Veuillez attendre le chargement du rapport ...', isError: false })
        const pos = responseJson.embedReport.length - 1
        this.setState({
          reportId: responseJson.embedReport[pos].reportId,
          embedUrl: responseJson.embedReport[pos].embedUrl,
          token: responseJson.embedToken.token
        })
      })
      .catch(error => {
        this.setState({ returnMessage: "Erreur : Une erreur s'est produite lors de la récupération du rapport Power Bi, vérifiez l'identifiant du rapport et du workspace !" })
        this.setState({
          isError: true,
          reportId: report.powerBiReportId,
          embedUrl: '',
          token: ''
        })
        console.error(error)
        sendRapportError(report.id, new Date().toJSON())
      })
    this.setState({ isLoaded: true })
  }

  public render () {
    const { iframe, reportType, reportId, embedUrl, token, rapportItem, isLoaded, isError, menuItems, isAnyReport, returnMessage, isPanelOpen } = this.state
    const subMenu = menuItems.map((rapport) => <li onClick={async () => await this.aquireToken(rapport)} className={rapportItem.id === rapport.id ? 'active-item' : ''} key={rapport.id}>{rapport.nom}</li>)
    let realSubMenus: any = ''

    const listeDesRoles = JSON.parse(localStorage.getItem('listeRoles') || '[]')
    const listeDesLibelles = listeDesRoles.map((role) => role.libelle)

    if (this.props.CategorieName === 'PRECO_TARIFAIRE') {
      if (subMenu.length === 0 && listeDesLibelles.includes('PRICING_T6')) {
        History.push('/pricingremiselineaire')
      }

      const pricingT6 =
                <>
                    <Link to='/pricingremiselineaire'>
                        <li>REMISE LINEAIRE</li>
                    </Link>
                    <Link to='/pricingremiselineaireparameters'>
                        <li>REMISE LINEAIRE PARAMETERS</li>
                    </Link>
                    <Link to='/pricingremisearticle'>
                        <li>REMISE ARTICLE</li>
                    </Link>
                    <Link to='/pricingremisearticleparameters'>
                        <li>REMISE ARTICLE PARAMETERS</li>
                    </Link>
                </>

      realSubMenus =
                <ul>
                    {subMenu.reverse()}
                    {(listeDesLibelles.includes('SUPER_ADMIN') || listeDesLibelles.includes('PRICING_T6')) ? pricingT6 : ''}
                </ul>
    } else {
      realSubMenus = <ul>{subMenu.reverse()}</ul>
    }

    const menuItemsList = {
      isActive: true,
      titreMenu: this.props.title,
      page: this.props.path,
      subPage: '',
      isOpen: isPanelOpen,
      subMenuList: realSubMenus
    }

    const headerItems = {
      title: this.props.title + ' / ',
      subtitle: rapportItem.nom !== undefined ? rapportItem.nom : 'Rapport Power BI'
    }

    return (
            <Fabric className="report-page">
                <div className="main-menu">
                    <Menu items={menuItemsList} />
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    <div className="ms-Grid header">
                        <Header items={headerItems} />
                    </div>
                </div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content less-top-margin">

                            {reportType === 0 && isLoaded && !isError && menuItems.length > 0
                              ? <PricingReportTypeZero
                                    id={reportId}
                                    embedUrl={embedUrl}
                                    accessToken={token}
                                    rapportDescription={rapportItem.description}
                                />
                              : reportType === 1 && isLoaded && !isError && menuItems.length > 0
                                ? <PricingReportTypeOne
                                    id={reportId}
                                    iframe={iframe}
                                    rapportDescription={rapportItem.description}
                                />
                                : <>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                            {!isAnyReport
                                              ? <h2>{returnMessage}</h2>
                                              : isError
                                                ? <>
                                                        <br></br>
                                                        <h2>{returnMessage}</h2>
                                                        <ErrorNotification />
                                                    </>
                                                : <Spinner size={SpinnerSize.large} />
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Fabric>
    )
  }

  handleAccordionClick () {
    const playImage = document.getElementById('play-image')
    if (playImage?.classList.contains('afficher')) { document.getElementById('play-image')?.classList.remove('afficher') } else { document.getElementById('play-image')?.classList.add('afficher') }
  };
}
