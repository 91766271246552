import { CompoundButton, Fabric } from '@fluentui/react'
import * as React from 'react'
import { adalApiFetch, authContext } from '../adalConfig'
import IllustrationNoAccess from '../medias/Illustration_No_Access.svg'
import Header from './Header'
import History from './History'
import Menu from './Menu'
import { IConfigurationAccueil } from './Accueil'

interface IUnauthorizedConfig {
  configuration: IConfigurationAccueil
}

export default class Unauthorized extends React.Component<any, IUnauthorizedConfig> {
  headerItems = {
    title: 'Accès non autorisé !',
    subtitle: ''
  }

  menuItems = {
    isActive: true,
    page: 'unauthorized',
    isOpen: false
  }

  constructor (props: any) {
    super(props)

    this.state = {
      configuration: {
        dateCreation: new Date(),
        dateModification: new Date(),
        emailSupport: '',
        estActif: false,
        id: '',
        idActualite: '',
        sujet: '',
        textePresentation: '',
        utilisateurCreation: '',
        utilisateurModification: ''
      }
    }
  }

  async componentDidMount () {
    await adalApiFetch(fetch, '/GetInformationsAccueil', {
      method: 'get',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          configuration: responseJson
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  render () {
    const listeDesRoles = JSON.parse(localStorage.getItem('listeRoles') || '[]')
    const renderRoles = listeDesRoles.map((role) => '  %0D    -    ' + role.libelle.replace('_', ' '))
    return (
            <Fabric>
                <div className="main-menu">
                    <Menu items={this.menuItems} />
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    <div className="ms-Grid header">
                        <Header items={this.headerItems} />
                    </div>
                </div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 accueil-page-content">
                            <p className="page-content-title"><b>Vous n’êtes pas autorisé à accéder à cette page.</b></p>
                            <p className="page-content-text">
                                Vous pouvez revenir à l’accueil en cliquant sur le bouton ci-dessous ou envoyer un message au support en précisant les informations suivantes :
                            </p>
                            <ul className="liste-informations">
                                <li>Dans l’objet « [Appli Acuity] Demande modification d’accès » </li>
                                <li>Dans le corps l’accès ou page  supplémentaire souhaité. </li>
                                <li>Les informations de connexion qui apparaissent ci-dessous :
                                    <ul className="liste-informations">
                                        <li><b>Nom et prénom :</b> { authContext.getCachedUser().profile.name }</li>
                                        <li><b>Adresse Email :</b> { authContext.getCachedUser().profile.email }</li>
                                        <li><b>Rôle(s) :</b> <ul className="liste-rapports">{ listeDesRoles.map((role) => <li key={role.libelle}>{role.libelle.replace('_', ' ')}</li>) }</ul></li>
                                    </ul>
                                </li>
                            </ul>
                            <br></br>
                            <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                                <CompoundButton primary onClick={() => History.push('/accueil')} className="unauthorized-btn">
                                    Retourner à la page d'accueil
                                </CompoundButton>
                                <a
                                    href={
                                        'mailto:' + this.state.configuration.emailSupport +
                                        '?subject=[Appli Acuity] Demande modification d’accès&body=' +
                                        '%0DNom et prénom :' + authContext.getCachedUser().profile.name +
                                        '%0DAdresse Email :' + authContext.getCachedUser().profile.email +
                                        '%0DRôle(s) :' + renderRoles
                                    }
                                >
                                    <CompoundButton default className="unauthorized-btn">
                                        Contacter le support
                                    </CompoundButton>
                                </a>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 no-access-image">
                            <img alt="Illustration" src={IllustrationNoAccess} />
                        </div>
                    </div>
                </div>
            </Fabric>
    )
  }
}
