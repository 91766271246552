import {
  Fabric
  , IIconProps
  , PrimaryButton
  , TextField
  , IDropdownOption
  , MessageBar
  , MessageBarType
  , Stack
  , IStackStyles
  , IStackProps
  , DefaultButton
  , IColumn
  , Dropdown
  , ITextFieldStyles
  , IDropdownStyles
} from '@fluentui/react'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { adalApiFetch } from '../../../adalConfig'
import Header from '../../Header'
import History from '../../History'
import Menu from '../../Menu'

const stackTokens = { childrenGap: 100 }

const stackStyles: Partial<IStackStyles> = { root: { width: 750 } }

const narrowTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 350 } }

const narrowDropDownStyles: Partial<IDropdownStyles> = { dropdown: { width: 350 } }

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 300 } }
}

const buttonProps: Partial<IStackProps> = {
  styles: { root: { width: 150, marginTop: 30 } },
  tokens: { childrenGap: 30 }
}

interface IRapport {
  id: string
  nom: string
  powerBiWorkspaceId: string
  powerBiReportId: string
  typeRapport: number
  description: string
  idCategorie: number
  categorie: ICategorie
  ordrePriorite: number
  estActif: string
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
}

interface ICategorie {
  id: number
  codeMetier: string
  libelle: string
  rapports: IRapport[]
}

interface IPowerBiConfig {
  columns: IColumn[]
  id: string
  nom: string
  powerBiWorkspaceId: string
  powerBiReportId: string
  typeRapport: number
  description: string
  idCategorie: number
  ordrePriorite: string
  estActif: string
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
  categories: any[]
  error: boolean
  updated: boolean
  CategorieDropdownOptions: IDropdownOption[]
  EtatDropDownoptions: IDropdownOption[]
  isPanelOpen: boolean
}

const SuccessExample = () => (
	<MessageBar
		messageBarType={MessageBarType.success}
		isMultiline={false}
	>
	  	Les données ont bien été mis à jour !
	</MessageBar>
)

const ErrorNotification = () => (
	<MessageBar
		messageBarType={MessageBarType.error}
		isMultiline={false}
	>
	  	Une erreur est survenue lors de la récupération / mis à jour des données, veuillez réessayer plus tard !
	</MessageBar>
)

export default class ModifierRapportPowerBi extends React.Component<RouteComponentProps<{rapportId: any}>, IPowerBiConfig> {
  headerItems = {
    title: 'Paramètres / ',
    subtitle: 'Modifier un rapport PowerBI'
  }

  saveIcon: IIconProps = { iconName: 'Save' }

  constructor (props: any) {
    super(props)

    this.state = {
      id: '',
      nom: '',
      powerBiWorkspaceId: '',
      powerBiReportId: '',
      typeRapport: -1,
      description: '',
      idCategorie: -1,
      ordrePriorite: '',
      estActif: '',
      dateCreation: new Date(),
      utilisateurCreation: '',
      dateModification: new Date(),
      utilisateurModification: '',
      columns: [],
      error: false,
      updated: false,
      categories: [],
      CategorieDropdownOptions: [],
      EtatDropDownoptions: [],
      isPanelOpen: true
    }
  }

  async componentDidMount () {
    const apiToken = window.localStorage.getItem('apiToken')
    const userId = window.localStorage.getItem('userId')

    await adalApiFetch(fetch, '/GetRapportByIdDetails', {
      headers: {
        Authorization: 'Bearer ' + apiToken,
        UtilisateurId: userId,
        RapportGuid: this.props.match.params.rapportId
      },
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          id: responseJson.id,
          nom: responseJson.nom,
          powerBiWorkspaceId: responseJson.powerBiWorkspaceId,
          powerBiReportId: responseJson.powerBiReportId,
          typeRapport: responseJson.typeRapport,
          description: responseJson.description,
          idCategorie: responseJson.idCategorie,
          ordrePriorite: responseJson.ordrePriorite,
          estActif: responseJson.estActif,
          dateCreation: responseJson.dateCreation,
          utilisateurCreation: responseJson.utilisateurCreation,
          dateModification: responseJson.dateModification,
          utilisateurModification: responseJson.utilisateurModification
        })
        this.setState({
          EtatDropDownoptions: [
            { key: 'true', text: 'Oui' },
            { key: 'false', text: 'Non' }
          ]
        })
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })

    await adalApiFetch(fetch, '/GetAllCategories', {
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ categories: responseJson })
        this.setState({ CategorieDropdownOptions: this.buildCategorieDropdownOptions() })
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })

    try {
      (() => {
        const closeButton = document.getElementsByClassName('ms-Panel-closeButton')[0]
        closeButton.addEventListener('click', (e: Event) => this.OpenPanel())
      })()
    } catch (error) {
      console.error(error)
    }
  }

  OpenPanel () {
    this.setState({ isPanelOpen: false })
  }

  submitHandler = (event: any) => {
    event.preventDefault()

    const data = {
      id: this.state.id,
      nom: this.state.nom,
      powerBiWorkspaceId: this.state.powerBiWorkspaceId,
      powerBiReportId: this.state.powerBiReportId,
      typeRapport: this.state.typeRapport,
      description: this.state.description,
      idCategorie: this.state.idCategorie,
      ordrePriorite: this.state.ordrePriorite,
      estActif: this.state.estActif,
      dateCreation: this.state.dateCreation,
      utilisateurCreation: this.state.utilisateurCreation,
      dateModification: new Date(),
      utilisateurModification: localStorage.getItem('userId') || this.state.utilisateurModification
    }

    const apiToken = window.localStorage.getItem('apiToken')
    const userId = window.localStorage.getItem('userId')

    adalApiFetch(fetch, '/UpdateRapport', {
      headers: {
        Authorization: 'Bearer ' + apiToken,
        UtilisateurId: userId,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ updated: true, error: false })
        setTimeout(() => {
          this.setState({ updated: false })
          History.push('/configurationPowerBi')
        }, 3000)
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })
  }

  render () {
    const { nom, powerBiWorkspaceId, powerBiReportId, description, idCategorie, ordrePriorite, estActif, EtatDropDownoptions, CategorieDropdownOptions, isPanelOpen } = this.state
    const menuItems = {
      isActive: true,
      page: 'Parametre',
      subPage: 'configurationPowerBi',
      subMenuList: '',
      isOpen: isPanelOpen
    }

    return (
            <form onSubmit={this.submitHandler}>
                <Fabric>
                    <div className="main-menu">
                        <Menu items={menuItems} />
                    </div>
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <div className="ms-Grid header">
                            <Header items={this.headerItems} />
                        </div>
                    </div>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                <p className="page-content-title less-top-margin"><i>Paramétrez et modifiez les informations rattachées à un rapport.</i></p>

                                <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
                                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                        <Stack {...columnProps}>
                                            <TextField label="Nom du rapport" styles={narrowTextFieldStyles} required name="nom" value={nom} onChange={this.onChangeHandler} />
                                            <TextField label="Workspace GUID" styles={narrowTextFieldStyles} required name="powerBiWorkspaceId" onChange={this.onChangeHandler} value={powerBiWorkspaceId} />
                                            <TextField label="Rapport GUID" styles={narrowTextFieldStyles} required name="powerBiReportId" onChange={this.onChangeHandler} value={powerBiReportId} />
                                        </Stack>
                                        <Stack {...columnProps}>
                                            <Dropdown
                                                styles={narrowDropDownStyles}
                                                label="Catégorie"
                                                required
                                                onChange={this.onChangeHandler}
                                                defaultSelectedKey={'idCategorie=' + idCategorie}
                                                options={
                                                    CategorieDropdownOptions.map(({ key, text }) => { return { key: 'idCategorie=' + key, text } })
                                                }
                                            />
                                            <TextField label="Ordre de priorité" styles={narrowTextFieldStyles} name="ordrePriorite" type="number" onChange={this.onChangeHandler} required value={ordrePriorite} />
                                            <Dropdown
                                                styles={narrowDropDownStyles}
                                                label="Est actif"
                                                required
                                                onChange={this.onChangeHandler}
                                                defaultSelectedKey={'estActif=' + estActif}
                                                options={
                                                    EtatDropDownoptions.map(({ key, text }) => { return { key: 'estActif=' + key, text } })
                                                }
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack tokens={stackTokens} styles={stackStyles}>
                                        <TextField
                                            multiline
                                            rows={6}
                                            label="Description"
                                            name="description"
                                            value= {description}
                                            onChange={this.onChangeHandler}
                                        />
                                    </Stack>
                                    <Stack horizontal {...buttonProps}>
                                        <Stack >
                                            <DefaultButton text="Annuler" allowDisabledFocus onClick={() => History.push('/configurationPowerBi')} />
                                        </Stack>

                                        <Stack >
                                            <PrimaryButton text="Sauvegarder" type="submit" allowDisabledFocus />
                                        </Stack>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                        <div className="notification-bottom">
                            { this.state.updated ? <SuccessExample /> : null }
                            { this.state.error ? <ErrorNotification /> : null }
                        </div>
                    </div>
                </Fabric>
            </form>
    )
  }

  onChangeHandler = (event: any, value: any) => {
    let stateName
    let stateValue

    if (event.target.id.indexOf('Dropdown') !== -1) {
      stateName = value.key.split('=')[0]
      stateValue = value.key.split('=')[1]
    } else {
      stateName = event.target.name
      stateValue = value
    }
    this.setState({ ...this.state, [stateName]: stateValue })
  }

  private buildCategorieDropdownOptions (): IDropdownOption[] {
    return this.state.categories.map(x => {
      return {
        key: x.id,
        text: x.libelle
      }
    })
  }
}
