import Menu from './Menu'
import Header from './Header'
import * as React from 'react'
import { Fabric } from '@fluentui/react'
import { adalApiFetch, authContext } from '../adalConfig'
import Truncate from 'react-truncate-html'
import Speaker from '../medias/loud-speaker.svg'
import IllustrationHome from '../medias/Illustration_home.svg'
import { ReactComponent as ArrowIcon } from '../medias/right-arrow.svg'
import { ReactComponent as SendMailIcon } from '../medias/send-mail.svg'
import { Link } from 'react-router-dom'

const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']

export interface IActualite {
  id: string
  dateRedaction: Date
  contenu: string
  doitAfficher: boolean
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
}

export interface IConfigurationAccueil {
  id: string
  estActif: boolean
  textePresentation: string
  emailSupport: string
  sujet: string
  idActualite: string
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
}

export interface IHomeConfig {
  userName: string
  configurationAccueil: IConfigurationAccueil
  actualite: IActualite
}

export default class Accueil extends React.Component<any, IHomeConfig> {
  headerItems = {
    title: 'Accueil',
    subtitle: ''
  }

  menuItems = {
    isActive: true,
    page: 'Accueil',
    subMenuList: '',
    isOpen: false
  }

  constructor (props: any) {
    super(props)

    this.state = {
      userName: '',
      configurationAccueil: {
        id: '',
        estActif: false,
        textePresentation: '',
        emailSupport: '',
        sujet: '',
        idActualite: '',
        dateCreation: new Date(),
        utilisateurCreation: '',
        dateModification: new Date(),
        utilisateurModification: ''
      },
      actualite: {
        id: '',
        dateRedaction: new Date(),
        contenu: '',
        doitAfficher: false,
        dateCreation: new Date(),
        utilisateurCreation: '',
        dateModification: new Date(),
        utilisateurModification: ''
      }
    }
  }

  async componentDidMount () {
    await adalApiFetch(fetch, '/GetInformationsAccueil', {
      method: 'get',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          configurationAccueil: {
            id: responseJson.id,
            estActif: responseJson.estActif,
            textePresentation: responseJson.textePresentation,
            emailSupport: responseJson.emailSupport,
            sujet: responseJson.sujet,
            idActualite: responseJson.idActualite,
            dateCreation: responseJson.dateCreation,
            utilisateurCreation: responseJson.utilisateurCreation,
            dateModification: responseJson.dateModification,
            utilisateurModification: responseJson.utilisateurModification
          }
        })
        if (responseJson.actualite !== null) {
          this.setState({
            actualite: {
              id: responseJson.actualite.id,
              dateRedaction: responseJson.actualite.dateRedaction,
              contenu: responseJson.actualite.contenu,
              doitAfficher: responseJson.actualite.doitAfficher,
              dateCreation: responseJson.actualite.dateCreation,
              utilisateurCreation: responseJson.actualite.utilisateurCreation,
              dateModification: responseJson.actualite.dateModification,
              utilisateurModification: responseJson.actualite.utilisateurModification
            }
          })
        }

        const connectedUserName = (authContext.getCachedUser().profile.name).split(' ')[0]
        this.setState({ userName: connectedUserName })
      })
      .catch(error => {
        console.error(error)
      })
  }

  render () {
    const { actualite, configurationAccueil, userName } = this.state

    return (
            <Fabric className="accueil-page">
                <div className="main-menu">
                    <Menu items={this.menuItems} />
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    <div className="ms-Grid header">
                        <Header items={this.headerItems} />
                    </div>
                </div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row accueil-top-content">
                        <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7 accueil-page-content">
                            <p className="page-content-title"><b>Bienvenue {userName}</b> sur l'Appli Acuity Pricing </p>
                            <p className="page-content-text">
                                <Truncate
                                    lines={10}
                                    dangerouslySetInnerHTML={{
                                      __html: configurationAccueil.textePresentation
                                    }}
                                />
                            </p>
                            <p className="page-content-text">Veuillez contacter le support en cas de besoin</p>
                            <p className="align-left container-btn-support">
                                <a href={'mailto:' + configurationAccueil.emailSupport + '?subject=' + configurationAccueil.sujet}>
                                    <span className="btn-support">
                                        <SendMailIcon width="25px"/>
                                            Support
                                        </span>
                                </a>
                            </p>
                            <br />
                            <p>Email : { configurationAccueil.emailSupport }</p>
                        </div>
                        <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5 accueil-page-image">
                            <img alt="Illustration" src={IllustrationHome} />
                        </div>
                    </div>

                    {actualite.doitAfficher
                      ? (
                        <div className="ms-Grid-row accueil-page-feed ms-sm11 ms-lg11">
                            <div className="feed-title">
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                    <img alt="Actualite" className="feed-title-icon" src={Speaker} width="25px" />
                                </div>
                                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                                    <b className="feed-title">Actualité</b>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 feed-date">
                                    <b>{this._onFormatDate(new Date(actualite.dateRedaction))}</b>
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7">
                                    <p className="feed-content">
                                        <Truncate
                                            lines={3}
                                            dangerouslySetInnerHTML={{
                                              __html: actualite.contenu
                                            }}
                                        />
                                    </p>
                                </div>
                                <div className="ms-Grid-col ms-sm1"></div>
                                <div className="ms-Grid-col ms-sm4 align-left">
                                <Link to='/actualiteContent'>
                                    <span className="btn-actualite">
                                        <ArrowIcon width="25px"/>
                                        Voir plus
                                    </span>
                                </Link>
                                </div>
                            </div>
                        </div>
                        )
                      : (<></>) }
                </div>
            </Fabric>
    )
  }

  private readonly _onFormatDate = (date: Date): string => {
    return days[date.getDay() - 1] + ' ' + date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
  }
}
