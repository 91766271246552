import {
  Fabric
  , IIconProps
  , PrimaryButton
  , TextField
  , IDropdownOption
  , MessageBar
  , MessageBarType
  , Stack
  , IStackStyles
  , IStackProps
  , DefaultButton
  , IColumn
  , Dropdown
  , ITextFieldStyles
  , IDropdownStyles
} from '@fluentui/react'
import * as React from 'react'
import { adalApiFetch } from '../../../adalConfig'
import Header from '../../Header'
import History from '../../History'
import Menu from '../../Menu'

const stackTokens = { childrenGap: 100 }

const stackStyles: Partial<IStackStyles> = { root: { width: 750 } }

const narrowTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 350 } }

const narrowDropDownStyles: Partial<IDropdownStyles> = { dropdown: { width: 350 } }

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 300 } }
}

const buttonProps: Partial<IStackProps> = {
  styles: { root: { width: 150, marginTop: 30 } },
  tokens: { childrenGap: 30 }
}

interface IRapport {
  id: string
  nom: string
  powerBiWorkspaceId: string
  powerBiReportId: string
  description: string
  idCategorie: number
  categorie: ICategorie
  ordrePriorite: number
  estActif: string
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
}

interface ICategorie {
  id: number
  codeMetier: string
  libelle: string
  rapports: IRapport[]
}

interface IPowerBiConfig {
  columns: IColumn[]
  id: string
  nom: string
  powerBiWorkspaceId: string
  powerBiReportId: string
  description: string
  idCategorie: number
  ordrePriorite: string
  estActif: string
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
  categories: any[]
  error: boolean
  updated: boolean
  CategorieDropdownOptions: IDropdownOption[]
  EtatDropDownoptions: any[]
  isPanelOpen: boolean
}

const SuccessExample = () => (
	<MessageBar
		messageBarType={MessageBarType.success}
		isMultiline={false}
	>
	  	Les données ont bien été mis à jour !
	</MessageBar>
)

const ErrorNotification = () => (
	<MessageBar
		messageBarType={MessageBarType.error}
		isMultiline={false}
	>
	  	Une erreur est survenue lors de la récupération / mis à jour des données, veuillez réessayer plus tard !
	</MessageBar>
)

export default class AjouterRapportPowerBi extends React.Component<any, IPowerBiConfig> {
  headerItems = {
    title: 'Paramètres / ',
    subtitle: 'Ajouter un rapport PowerBI'
  }

  saveIcon: IIconProps = { iconName: 'Save' }

  constructor (props: any) {
    super(props)

    this.state = {
      id: '',
      nom: '',
      powerBiWorkspaceId: '',
      powerBiReportId: '',
      description: '',
      idCategorie: -1,
      ordrePriorite: '',
      estActif: '',
      dateCreation: new Date(),
      utilisateurCreation: localStorage.getItem('userId') || '',
      dateModification: new Date(),
      utilisateurModification: localStorage.getItem('userId') || '',
      columns: [],
      error: false,
      updated: false,
      categories: [],
      CategorieDropdownOptions: [],
      EtatDropDownoptions: [],
      isPanelOpen: true
    }
  }

  async componentDidMount () {
    this.setState({
      id: '',
      nom: '',
      powerBiWorkspaceId: '',
      powerBiReportId: '',
      description: '',
      idCategorie: -1,
      ordrePriorite: '',
      estActif: '',
      dateCreation: new Date(),
      utilisateurCreation: localStorage.getItem('userId') || '',
      dateModification: new Date(),
      utilisateurModification: localStorage.getItem('userId') || '',
      columns: [],
      error: false,
      updated: false,
      categories: [],
      CategorieDropdownOptions: [],
      EtatDropDownoptions: []
    })

    await adalApiFetch(fetch, '/GetAllCategories', {
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ categories: responseJson })
        this.setState({ CategorieDropdownOptions: this.buildCategorieDropdownOptions() })
        this.setState({
          EtatDropDownoptions: [
            { key: 'true', text: 'Oui' },
            { key: 'false', text: 'Non' }
          ]
        })
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })

    try {
      (() => {
        const closeButton = document.getElementsByClassName('ms-Panel-closeButton')[0]
        closeButton.addEventListener('click', (e: Event) => this.OpenPanel())
      })()
    } catch (error) {
      console.error(error)
    }
  }

  OpenPanel () {
    this.setState({ isPanelOpen: false })
  }

  handleValidation () {
    return false
  }

  handleChangeUserName = (e: any) => {
    if (e.target.name === 'powerBiWorkspaceId') {
      if (e.target.value.match('^[a-zA-Z ]*$') !== null) {
        this.setState({ powerBiWorkspaceId: e.target.value })
      }
    } else {
      if (e.target.value.match('^[a-zA-Z ]*$') !== null) {
        this.setState({ powerBiReportId: e.target.value })
      }
    }
  }

  submitHandler = (event: any) => {
    event.preventDefault()

    const data = {
      nom: this.state.nom,
      powerBiWorkspaceId: this.state.powerBiWorkspaceId,
      powerBiReportId: this.state.powerBiReportId,
      description: this.state.description,
      idCategorie: this.state.idCategorie,
      typeRapport: 0,
      ordrePriorite: this.state.ordrePriorite,
      estActif: this.state.estActif,
      dateCreation: this.state.dateCreation,
      dateModification: new Date(),
      utilisateurCreation: this.state.utilisateurCreation,
      utilisateurModification: this.state.utilisateurModification
    }

    const apiToken = window.localStorage.getItem('apiToken')
    const userId = window.localStorage.getItem('userId')

    adalApiFetch(fetch, '/AddRapportWithRoles', {
      headers: {
        Authorization: 'Bearer ' + apiToken,
        UtilisateurId: userId,
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ updated: true, error: false })
        setTimeout(() => {
          this.setState({ updated: false })
          History.push('/configurationPowerBi')
        }, 5000)
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })
  }

  render () {
    const { isPanelOpen, EtatDropDownoptions, CategorieDropdownOptions } = this.state
    const menuItems = {
      isActive: true,
      page: 'Parametre',
      subPage: 'configurationPowerBi',
      subMenuList: '',
      isOpen: isPanelOpen
    }

    return (
            <form onSubmit={this.submitHandler}>
                <Fabric>
                    <div className="main-menu">
                        <Menu items={menuItems} />
                    </div>
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <div className="ms-Grid header">
                            <Header items={this.headerItems} />
                        </div>
                    </div>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                <p className="page-content-title less-top-margin"><i>Ajout nouveau rapport.</i></p>

                                <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
                                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                        <Stack {...columnProps}>
                                            <TextField label="Nom du rapport" styles={narrowTextFieldStyles} required name="nom" placeholder="Nom du rapport" onChange={this.onChangeHandler} />
                                            <TextField label="Workspace GUID" styles={narrowTextFieldStyles} required name="powerBiWorkspaceId" onChange={this.onChangeHandler} placeholder="00000000-0000-0000-0000-000000000000" />
                                            <TextField label="Rapport GUID" styles={narrowTextFieldStyles} required name="powerBiReportId" onChange={this.onChangeHandler} placeholder="00000000-0000-0000-0000-000000000000" />
                                        </Stack>
                                        <Stack {...columnProps}>
                                            <Dropdown
                                                label="Catégorie"
                                                required
                                                placeholder="Choisir une valeur"
                                                styles={narrowDropDownStyles}
                                                options={
                                                    CategorieDropdownOptions.map(({ key, text }) => { return { key: 'idCategorie=' + key, text } })
                                                }
                                                onChange={this.onChangeHandler}
                                            />
                                            <TextField label="Ordre de priorité (1 à 1 000 000)" styles={narrowTextFieldStyles} name="ordrePriorite" type="number" onChange={this.onChangeHandler} required placeholder="Ex. 100" />
                                            <Dropdown
                                                label="Est actif"
                                                required
                                                styles={narrowDropDownStyles}
                                                placeholder="Choisir une valeur"
                                                options={
                                                    EtatDropDownoptions.map(({ key, text }) => { return { key: 'estActif=' + key, text } })
                                                }
                                                onChange={this.onChangeHandler}
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack tokens={stackTokens} styles={stackStyles}>
                                        <TextField
                                            multiline
                                            rows={6}
                                            name="description"
                                            label="Description"
                                            placeholder="Description du rapport"
                                            onChange={this.onChangeHandler}
                                        />
                                    </Stack>
                                    <Stack horizontal {...buttonProps}>
                                        <Stack >
                                            <DefaultButton text="Annuler" allowDisabledFocus onClick={() => History.push('/configurationPowerBi')} />
                                        </Stack>

                                        <Stack >
                                            <PrimaryButton text="Sauvegarder" type="submit" allowDisabledFocus />
                                        </Stack>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                        <div className="notification-bottom">
                            { this.state.updated ? <SuccessExample /> : null }
                            { this.state.error ? <ErrorNotification /> : null }
                        </div>
                    </div>
                </Fabric>
            </form>
    )
  }

  onChangeHandler = (event: any, value: any) => {
    let stateName
    let stateValue

    if (event.target.id.indexOf('Dropdown') !== -1) {
      stateName = value.key.split('=')[0]
      stateValue = value.key.split('=')[1]
    } else {
      stateName = event.target.name
      stateValue = value
    }
    this.setState({ ...this.state, [stateName]: stateValue })
  }

  private buildCategorieDropdownOptions (): IDropdownOption[] {
    return this.state.categories.map(x => {
      return {
        key: x.id,
        text: x.libelle
      }
    })
  }
}
