import * as React from 'react'
import moment from 'moment'
import { Fabric } from '@fluentui/react'
import Menu from '../Menu'
import Header from '../Header'

interface Props{
  menuItemsList: any
  headerItems: any
  message: any
}

export function _formPrice (price: number) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  }).format(price)
}

export function _formatDate (date: Date) {
  return date === undefined ? '' : moment(date).format('DD/MM/YYYY')
}

export function _formatDateHeure (date: Date) {
  return date === undefined ? '' : moment(date).format('DD/MM/YYYY hh:mm:ss')
}

export default function EmptyContent (props: Props) {
  return (
        <Fabric>
            <div className="main-menu">
                <Menu items={props.menuItemsList} />
            </div>
            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                <div className="ms-Grid header">
                    <Header items={props.headerItems} />
                </div>
            </div>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md11 ms-lg10 accueil-page-content">
                        <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg12">
                            <h2>{props.message}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </Fabric>
  )
}
