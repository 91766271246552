import { adalApiFetch } from '../../adalConfig'

export const initCategory = async (props, onInitCategorySuccess, onInitCategoryFailed) => {
  await adalApiFetch(fetch, '/GetCategorieByName', {
    headers: { CategorieName: props.CategorieName },
    method: 'get'
  })
    .then(response => response.json())
    .then(responseJson => {
      onInitCategorySuccess(responseJson)
    })
    .catch(error => {
      onInitCategoryFailed()
      console.error(error)
    })
}

export const loadCodesReferentiels = async (setCodesReferentiels, onLoadCodesReferentielsError) => {
  const apiToken = window.localStorage.getItem('apiToken')
  const userId = window.localStorage.getItem('userId')

  await adalApiFetch(fetch, '/pricing-remise/codes-referentiel', {
    headers: {
      Authorization: 'Bearer ' + apiToken,
      UtilisateurId: userId,
      'Content-Type': 'application/json'
    },
    method: 'get'
  })
    .then(response => response.json())
    .then(responseJson => {
      setCodesReferentiels(responseJson)
    })
    .catch(error => {
      alert('une erreur est survenue lors de la récupération des données référentiels')
      onLoadCodesReferentielsError()
      console.error(error)
    })
}

export const loadReportsForMenu = async (idCat, onLoadReportsSuccess, onLoadReportsFailed) => {
  const apiToken = window.localStorage.getItem('apiToken')
  const userId = window.localStorage.getItem('userId')

  await adalApiFetch(fetch, '/GetAuthorizedReports', {
    headers: {
      Authorization: 'Bearer ' + apiToken,
      UtilisateurId: userId,
      CategorieId: idCat,
      'Content-Type': 'application/json'
    },
    method: 'get'
  })
    .then(response => response.json())
    .then(responseJson => {
      onLoadReportsSuccess(responseJson)
      localStorage.setItem('reportsMenuItems', JSON.stringify(responseJson))
    })
    .catch(error => {
      onLoadReportsFailed()
      console.error(error)
    })
}
