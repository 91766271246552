import * as React from 'react'
import { Fabric } from 'office-ui-fabric-react/lib/Fabric'
import { adalApiFetch } from '../../adalConfig'
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn
} from 'office-ui-fabric-react/lib/DetailsList'
import { Dropdown, IDropdownOption, MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react'
import Header from '../Header'
import Menu from '../Menu'

const DropdownChoice = [
  { key: 'true', text: 'Oui', className: 'true-value' },
  { key: 'false', text: 'Non', className: 'false-value' }
]

interface IRapport {
  id: string
  nom: string
  typeRapport: number
  lien: string
  description: string
  ordrePriorite: number
  estActif: string
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
  rapportsRoleAcces: IRoleAcces[]
}

interface IKeyValue {
  key: number
  value: boolean
}

interface IRoleAcces {
  idRapport: string
  idRoleAcces: number
  peutAcceder: boolean
}

interface IRapportsRole {
  key: string
  id: string
  nom: string
  typeRapport: number
  keyUser: IKeyValue
  siegeAll: IKeyValue
  siegeVl: IKeyValue
  siegePl: IKeyValue
  enseigneVl: IKeyValue
  enseignePl: IKeyValue
  superAdmin: IKeyValue
  roleId: number
  rapportId: string
  categorie: string
}

interface IRoleConfig {
  columns: IColumn[]
  items: IRapportsRole[]
  itemsAll: IRapport[]
  error: boolean
  updated: boolean
  categories: any[]
  CategorieDropdownOptions: IDropdownOption[]
  loaded: boolean
  isPanelOpen: boolean // Determine si le panel du sous menu est ouvert ou pas
  selectedCategory: string
  selectedTypeRapport: string
  typeRapport: any[]
}

const SuccessExample = () => (
    <MessageBar
        messageBarType={MessageBarType.success}
        isMultiline={false}
    >
        Les données ont bien été mis à jour !
    </MessageBar>
)

const ErrorNotification = () => (
    <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
    >
        Une erreur est survenue lors de la récupération / mis à jour des données, veuillez réessayer plus tard !
    </MessageBar>
)
export default class Role extends React.Component<any, IRoleConfig> {
  private _allItems: IRapportsRole[]

  headerItems = {
    title: 'Paramètres / ',
    subtitle: 'Rôles et accès des utilisateurs'
  }

  constructor (props: {}) {
    super(props)

    this._allItems = []

    this.state = {
      items: [],
      columns: [],
      categories: [],
      error: false,
      updated: false,
      itemsAll: [],
      CategorieDropdownOptions: [],
      loaded: false,
      isPanelOpen: true, // Pour ouvrir le panel du sous menu lors du chargement du composant
      selectedCategory: '',
      selectedTypeRapport: '',
      typeRapport: []
    }
  }

  async componentDidMount () {
    await this.getAllItems()

    this.setState({
      typeRapport: [
        {
          key: '',
          text: 'Choisir un type de rapport'
        },
        {
          key: 0,
          text: 'PowerBi'
        },
        {
          key: 1,
          text: 'MicroStrategy'
        }
      ]
    })

    this.setState({
      items: this.state.selectedCategory || this.state.selectedTypeRapport !== ''
        ? this._allItems.filter(i => {
          if (this.state.selectedCategory && this.state.selectedTypeRapport !== '') { return (i.typeRapport === parseInt(this.state.selectedTypeRapport) && i.categorie === this.state.selectedCategory) } else if (this.state.selectedCategory) { return (i.categorie === this.state.selectedCategory) } else if (this.state.selectedTypeRapport !== '') { return (i.typeRapport === parseInt(this.state.selectedTypeRapport)) }
        })
        : this._allItems
    })

    await adalApiFetch(fetch, '/GetAllCategories', {
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ categories: responseJson })
        this.setState({ CategorieDropdownOptions: this.buildCategorieDropdownOptions() })
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })

    await this.updateColumnState()

    try {
      (() => {
        const closeButton = document.getElementsByClassName('ms-Panel-closeButton')[0]
        closeButton.addEventListener('click', (e: Event) => this.OpenPanel())
      })()
    } catch (error) {
      console.error(error)
    }

    this.setState({ loaded: true })
  }

  async getAllItems () {
    const apiToken = window.localStorage.getItem('apiToken')
	    const userId = window.localStorage.getItem('userId')

    await adalApiFetch(fetch, '/GetListRapportRole', {
      headers: {
        Authorization: 'Bearer ' + apiToken,
        UtilisateurId: userId
      },
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          items: responseJson
        })
        this._allItems = this.state.items
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })
  }

  async updateColumnState () {
    this.setState({
      columns: [
        {
          key: 'nom', name: 'Nom du rapport', fieldName: 'nom', minWidth: 180, maxWidth: 180, onColumnClick: this._onColumnClick, isMultiline: true
        },
        {
          key: 'typeRapport',
          name: 'Type de rapport',
          fieldName: 'typeRapport',
          minWidth: 180,
          maxWidth: 180,
          isSorted: true,
          onColumnClick: this._onColumnClick,
          onRender: (item: IRapportsRole) => {
            let typerRapport = 'Undefined'
            switch (item.typeRapport) {
              case 0:
                typerRapport = 'PowerBi'
                break
              case 1:
                typerRapport = 'MicroStrategy'
                break
            }
            return (typerRapport)
          }
        },
        {
          key: 'categorie', name: 'Catégorie', fieldName: 'categorie', minWidth: 180, maxWidth: 180, isSorted: true, onColumnClick: this._onColumnClick
        },
        {
          key: 'keyUser',
          name: 'KEY USER',
          fieldName: 'keyUser',
          minWidth: 90,
          maxWidth: 90,
          onColumnClick: this._onColumnClick,
          onRender: (item: IRapportsRole) => {
            return (
                            <Dropdown
                                className={item.keyUser.value ? 'role-dropdown-choice true-value' : 'role-dropdown-choice false-value'}
                                options={DropdownChoice}
                                selectedKey={String(item.keyUser.value)}
                                onChange={this.onChangeSelection(item.keyUser.key, item.rapportId)}
                            />
            )
          }
        },
        {
          key: 'siegeAll',
          name: 'SIEGE - ALL',
          fieldName: 'siegeAll',
          minWidth: 80,
          maxWidth: 90,
          onColumnClick: this._onColumnClick,
          onRender: (item: IRapportsRole) => {
            return (
                            <Dropdown
                                className={item.siegeAll.value ? 'role-dropdown-choice true-value' : 'role-dropdown-choice false-value'}
                                options={DropdownChoice}
                                selectedKey={String(item.siegeAll.value)}
                                onChange={this.onChangeSelection(item.siegeAll.key, item.rapportId)}
                            />
            )
          }
        },
        {
          key: 'siegeVl',
          name: 'SIEGE - VL',
          fieldName: 'siegeVl',
          minWidth: 80,
          maxWidth: 90,
          onColumnClick: this._onColumnClick,
          onRender: (item: IRapportsRole) => {
            return (
                            <Dropdown
                                className={item.siegeVl.value ? 'role-dropdown-choice true-value' : 'role-dropdown-choice false-value'}
                                options={DropdownChoice}
                                selectedKey={String(item.siegeVl.value)}
                                onChange={this.onChangeSelection(item.siegeVl.key, item.rapportId)}
                            />
            )
          }
        },
        {
          key: 'siegePl',
          name: 'SIEGE - PL',
          fieldName: 'siegePl',
          minWidth: 80,
          maxWidth: 90,
          onColumnClick: this._onColumnClick,
          onRender: (item: IRapportsRole) => {
            return (
                            <Dropdown
                                className={item.siegePl.value ? 'role-dropdown-choice true-value' : 'role-dropdown-choice false-value'}
                                options={DropdownChoice}
                                selectedKey={String(item.siegePl.value)}
                                onChange={this.onChangeSelection(item.siegePl.key, item.rapportId)}
                            />
            )
          }
        },
        {
          key: 'enseigneVl',
          name: 'ENSEIGNE - VL',
          fieldName: 'enseigneVl',
          minWidth: 110,
          maxWidth: 110,
          onColumnClick: this._onColumnClick,
          onRender: (item: IRapportsRole) => {
            return (
                            <Dropdown
                                className={item.enseigneVl.value ? 'role-dropdown-choice true-value' : 'role-dropdown-choice false-value'}
                                options={DropdownChoice}
                                selectedKey={String(item.enseigneVl.value)}
                                onChange={this.onChangeSelection(item.enseigneVl.key, item.rapportId)}
                            />
            )
          }
        },
        {
          key: 'enseignePl',
          name: 'ENSEIGNE - PL',
          fieldName: 'enseignePl',
          minWidth: 110,
          maxWidth: 110,
          onColumnClick: this._onColumnClick,
          onRender: (item: IRapportsRole) => {
            return (
                            <Dropdown
                                className={item.enseignePl.value ? 'role-dropdown-choice true-value' : 'role-dropdown-choice false-value'}
                                options={DropdownChoice}
                                selectedKey={String(item.enseignePl.value)}
                                onChange={this.onChangeSelection(item.enseignePl.key, item.rapportId)}
                            />
            )
          }
        },
        {
          key: 'superAdmin',
          name: 'SUPER ADMIN',
          fieldName: 'superAdmin',
          minWidth: 100,
          maxWidth: 110,
          onColumnClick: this._onColumnClick,
          onRender: (item: IRapportsRole) => {
            return (
                            <Dropdown
                                className={item.superAdmin.value ? 'role-dropdown-choice true-value' : 'role-dropdown-choice false-value'}
                                options={DropdownChoice}
                                selectedKey={String(item.superAdmin.value)}
                                onChange={this.onChangeSelection(item.superAdmin.key, item.rapportId)}
                            />
            )
          }
        }
      ]
    })
  }

  OpenPanel () {
    this.setState({ isPanelOpen: false })
  }

  onChangeSelection = (idRole: number, idRapport: string) => (event: any, value: any) => {
    const data = {
      idRapport,
      idRoleAcces: idRole,
      peutAcceder: value.key
    }

    const apiToken = window.localStorage.getItem('apiToken')
	    const userId = window.localStorage.getItem('userId')

    adalApiFetch(fetch, '/UpdateRapportRole', {
      headers: {
        Authorization: 'Bearer ' + apiToken,
			    UtilisateurId: userId,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responseJson => {
        event.target.className += ' ' + value.key + '-value'

        this.setState({
          updated: true,
          error: false
        })

        this.componentDidMount()

        setTimeout(() => {
          this.setState({ updated: false })
        }, 5000)
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })
  }

  public render () {
    const { columns, items, CategorieDropdownOptions, loaded, isPanelOpen, typeRapport } = this.state
    const menuItems = {
      isActive: true,
      page: 'Parametre',
      subPage: 'role',
      subMenuList: '',
      isOpen: isPanelOpen
    }

    return (
            <Fabric>
                <div className="main-menu">
                    <Menu items={menuItems} />
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    <div className="ms-Grid header">
                        <Header items={this.headerItems} />
                    </div>
                </div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        {loaded
                          ? (
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                <p className="page-content-title less-top-margin"><i>Modifiez les accès de vos groupes aux différents rapports existants.</i></p>

                                <div className="ms-Grid-col ms-sm4 ms-md3 ms-lg3 report-filter">
                                    <Dropdown
                                        label="Filtrer par catégorie"
                                        placeholder="Choisir une valeur"
                                        options={CategorieDropdownOptions.map(({ key, text }) => { return { key, text } })}
                                        onChange={this._onCategorieFilter}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm4 ms-md3 ms-lg3 report-filter">
                                    <Dropdown
                                        label="Filtrer par type de rapport"
                                        placeholder="Choisir une valeur"
                                        options={typeRapport.map(({ key, text }) => { return { key, text } })}
                                        onChange={this._onTypeRapportFilter}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg12">
                                    <DetailsList
                                        className="role-page-list"
                                        items={items}
                                        columns={columns}
                                        selectionMode={SelectionMode.none}
                                        getKey={this._getKey}
                                        setKey="set"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        selectionPreservedOnEmptyClick={false}
                                        ariaLabelForSelectionColumn="Toggle selection"
                                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                        checkButtonAriaLabel="Row checkbox"
                                    />
                                </div>
                            </div>
                            )
                          : (
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                        <p className="page-content-title"><i>Modifiez les accès de vos groupes aux différents rapports existants.</i></p>
                                        <Spinner size={SpinnerSize.large} />
                                    </div>
                                </div>
                            </div>
                            )}
                    </div>
                    <div className="notification-bottom">
                        {this.state.updated ? <SuccessExample /> : null}
                        {this.state.error ? <ErrorNotification /> : null}
                    </div>
                </div>
            </Fabric>
    )
  }

  private _getKey (item: any, index?: number): string {
    return item.key
  }

  private readonly _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state
    const newColumns: IColumn[] = columns.slice()
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0]

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })
    const newItems = _copyAndSort(items, currColumn.key, currColumn.isSortedDescending)
    this.setState({ columns: newColumns })
    this.setState({ items: newItems })
  }

  private buildCategorieDropdownOptions (): IDropdownOption[] {
    const firstItem = [{
      key: '',
      text: 'Choisir une catégorie'
    }]

    const temp = this.state.categories.map(x => {
      return {
        key: x.libelle,
        text: x.libelle
      }
    })

    return firstItem.concat(temp)
  }

  private readonly _onCategorieFilter = (event: any, value: any) => {
    this.setState({ selectedCategory: value.key })
    this.setState({
      items: value.key || this.state.selectedTypeRapport !== ''
        ? this._allItems.filter(i => {
          if (value.key && this.state.selectedTypeRapport !== '') { return (i.typeRapport === parseInt(this.state.selectedTypeRapport) && i.categorie === value.key) } else if (value.key) { return (i.categorie === value.key) } else if (this.state.selectedTypeRapport !== '') { return (i.typeRapport === parseInt(this.state.selectedTypeRapport)) }
        })
        : this._allItems
    })
  }

  private readonly _onTypeRapportFilter = (event: any, value: any) => {
    this.setState({ selectedTypeRapport: value.key })
    this.setState({
      items: this.state.selectedCategory || value.key !== ''
        ? this._allItems.filter(i => {
          if (this.state.selectedCategory && value.key !== '') { return (i.typeRapport === value.key && i.categorie === this.state.selectedCategory) } else if (value.key !== '') { return (i.typeRapport === value.key) } else if (this.state.selectedCategory) { return (i.categorie === this.state.selectedCategory) }
        })
        : this._allItems
    })
  }
}

function _copyAndSort<IRapportsRole> (items: IRapportsRole[], columnKey: string, isSortedDescending?: boolean): IRapportsRole[] {
  const key = columnKey

  if (key !== 'categorie' && key !== 'nom') {
    return items.slice(0).sort((a: IRapportsRole, b: IRapportsRole) => ((isSortedDescending ? a[key].value < b[key].value : a[key].value > b[key].value) ? 1 : -1))
  } else {
    return items.slice(0).sort((a: IRapportsRole, b: IRapportsRole) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
  }
}
