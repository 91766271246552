import { PowerBIEmbed } from 'powerbi-client-react'
import React from 'react'
import {
  Accordion
  , AccordionItem
  , AccordionItemHeading
  , AccordionItemButton
  , AccordionItemPanel
} from 'react-accessible-accordion'
import Play from '../../medias/play.svg'

interface IPricingReportTypeOne {
  id: string
  iframe: any
  rapportDescription: string
}

export default class PricingReportTypeOne extends React.Component<IPricingReportTypeOne, any> {
  handleAccordionClick () {
    const playImage = document.getElementById('play-image')
    if (playImage?.classList.contains('afficher')) { document.getElementById('play-image')?.classList.remove('afficher') } else { document.getElementById('play-image')?.classList.add('afficher') }
  };

  public render () {
    return (
            <>
                <Accordion allowZeroExpanded className="accordion-actualite">
                    <AccordionItem onClick={this.handleAccordionClick}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2><img alt="Rapport" className="report-play-icon" id="play-image" src={Play} width="10px" /> Information</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className="report-description">{this.props.rapportDescription}</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>

                <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg12 report-container">
                    <div id="dossierContainer" style={{ display: 'none' }}></div>
                    {this.props.iframe}
                </div>
            </>
    )
  }
}
