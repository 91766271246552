import * as React from 'react'
import {
  DefaultButton,
  Fabric,
  getFocusStyle,
  getTheme,
  mergeStyleSets,
  PrimaryButton,
  Separator,
  Spinner,
  TextField
} from '@fluentui/react'
import Header from '../Header'

import '@ag-grid-community/core/dist/styles/ag-grid.css'
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css'
import {
  ModuleRegistry,
  AllCommunityModules
} from '@ag-grid-community/all-modules'
import { PreconisationMenuItems } from '../shared/preconisationMenuItems'
import { adalApiFetch } from '../../adalConfig'
import Serializable from '../interface/Serializable'
import { OptionDialog } from '../shared/optionDialog'
import { SettingPricingLineaire } from './pricingRemiseLineaire'
import { PricingRemiseLineaireDetails } from '../shared/pricingRemiseLineaireDetails'
import { useHistory } from 'react-router-dom'

export class RemiseLineaireParameters implements Serializable<RemiseLineaireParameters> {
  createdAt?: string
  id?: string
  modifiedAt?: string
  name?: string
  remiseParameters?: SettingPricingLineaire[]

  deserialize (input: Record<string, unknown>): RemiseLineaireParameters {
    this.createdAt = input.createdAt as string
    this.id = input.id as string
    this.modifiedAt = input.modifiedAt as string
    this.name = input.name as string
    this.remiseParameters = (input.remiseLineaireParameters as Array<Record<string, unknown>>).map(value => new SettingPricingLineaire().deserialize(value))
    return this
  }
}

export interface IPricingRemiseLineaireParameters {
  CategorieName: string
}

ModuleRegistry.registerModules(AllCommunityModules)

const theme = getTheme()
const { palette, semanticColors, fonts } = theme
const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
      selectors: {
        '&:hover': { background: palette.neutralLight }
      }
    }
  ],
  itemContent: {
    marginLeft: 10,
    overflow: 'hidden',
    flexGrow: 1
  },
  itemName: [
    fonts.xLarge,
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10
  }
})

export const PricingRemiseLineaireParameters: React.FunctionComponent<IPricingRemiseLineaireParameters> = props => {
  const [isInitializing, setIsInitializing] = React.useState(true)
  const [originalData, setOriginalData] = React.useState<RemiseLineaireParameters[]>([])
  const [items, setItems] = React.useState<RemiseLineaireParameters[]>([])
  const [isBusy, setIsBusy] = React.useState(false)
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = React.useState(false)
  const [idToDelete, setIdToDelete] = React.useState('')
  const history = useHistory()

  React.useEffect(() => {
    adalApiFetch(fetch, '/pricing-remise/remise-lineaire/parameter', {
      method: 'get'
    })
      .then(response => (response.json() as Array<Record<string, unknown>>))
      .then(responseJson => {
        setOriginalData(responseJson.map(value => new RemiseLineaireParameters().deserialize(value)))
        setIsInitializing(false)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  React.useEffect(() => {
    setItems(originalData)
  }, [originalData])

  const onEditClicked = (id: string): void => {
    history.push(`/pricingremiselineaire?id=${id}`)
  }

  const onDeleteClicked = (id: string): void => {
    setIdToDelete(id)
    setShouldShowDeleteDialog(true)
  }

  const onDeleteConfirmed = async (): Promise<void> => {
    setIsBusy(true)
    await adalApiFetch(fetch, `/pricing-remise/remise-lineaire/parameter/${idToDelete}`, {
      method: 'delete'
    })
      .catch(error => {
        alert('Saving failed, please try again later.')
        console.error(error)
      })
      .finally(() => {
        setOriginalData(originalData.filter(v => v.id !== idToDelete))
        setShouldShowDeleteDialog(false)
        setIsBusy(false)
      })
  }

  const onFilterChanged = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
    setItems(originalData.filter(item => item.name!.toLowerCase().includes(text != null ? text.toLowerCase() : '')))
  }

  return (
    <Fabric>
        <PreconisationMenuItems CategorieName={props.CategorieName} SubPageName='pricingremiselineaireparameters' />
        <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
            <div className="ms-Grid header">
                <Header items={{ title: 'Remise Lineaire Parameters', subtitle: '' }} />
            </div>
        </div>
        <div style={{ boxShadow: theme.effects.elevation4, paddingLeft: 10, paddingBottom: 10, paddingRight: 10 }}>
            <h2 className="ms-md12">Paramétrage</h2>
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12" >
                    <Separator alignContent="start" />
                  </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col" style={{ height: 1200, width: '100%', overflow: 'auto' }}>
                        { isInitializing &&
                          <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                            <Spinner label="loading..." ariaLive="assertive" labelPosition="left" />
                          </div>
                        }
                        { originalData.length > 0 && !isInitializing && <div className="ms-Grid-row" style={{ margin: 20 }}><TextField label='Filter by name' onChange={onFilterChanged} /></div> }
                        { originalData.length === 0 && !isInitializing && <div className="ms-Grid-row" style={{ margin: 20 }}>Empty saved parameters</div>}
                        { items.length === 0 && originalData.length > 0 && !isInitializing && <div className="ms-Grid-row" style={{ margin: 20 }}>Empty search results</div>}
                        { items.map((d, idx) => (
                            <div className={classNames.itemCell} key={idx} data-is-focusable={true}>
                                <div className={classNames.itemContent}>
                                    <div className={classNames.itemName}>{d.name}</div>
                                    <div className={classNames.itemIndex}>{`Modified at: ${d.modifiedAt!}`}</div>
                                    <PricingRemiseLineaireDetails data={d.remiseParameters!} isReadonly={true} isDisabled={true} />
                                    <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                                        <div className="ms-Grid-col ms-sm12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <PrimaryButton text="Edit" onClick={() => onEditClicked(d.id!)} style={{ marginRight: 10 }}/>
                                            <DefaultButton text="Delete" onClick={() => onDeleteClicked(d.id!)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        </div>
        { shouldShowDeleteDialog && <OptionDialog onDelete={onDeleteConfirmed} onDismiss={() => setShouldShowDeleteDialog(false)} title='Confirmation' description='Please confirm the deletion' isBusy={isBusy}/> }
    </Fabric>
  )
}
