import * as React from 'react'
import Header from '../../Header'
import Menu from '../../Menu'
import history from '../../History'
import { Fabric } from 'office-ui-fabric-react/lib/Fabric'
import { adalApiFetch } from '../../../adalConfig'
import { _formatDateHeure } from '../../shared/sharedComponent'
import {
  useId
  , useBoolean
} from '@uifabric/react-hooks'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn
} from 'office-ui-fabric-react/lib/DetailsList'
import {
  CommandButton
  , DefaultButton
  , Dialog
  , DialogFooter
  , DialogType
  , Dropdown
  , IDropdownOption
  , IIconProps
  , MarqueeSelection
  , MessageBar
  , MessageBarType
  , PrimaryButton
  , Spinner
  , SpinnerSize
  , TextField
} from '@fluentui/react'

const addIcon: IIconProps = { iconName: 'Add' }
const editIcon: IIconProps = { iconName: 'Edit' }
const removeIcon: IIconProps = { iconName: 'Remove' }

interface IRapport {
  id: string
  nom: string
  powerBiWorkspaceId: string
  powerBiReportId: string
  description: string
  idCategorie: number
  categorie: ICategorie
  ordrePriorite: number
  estActif: string
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
}

interface ICategorie {
  id: number
  codeMetier: string
  libelle: string
  rapports: IRapport[]
}

interface IPowerBiConfig {
  columns: IColumn[]
  categories: any[]
  CategorieDropdownOptions: IDropdownOption[]
  items: IRapport[]
  error: boolean
  updated: boolean
  selectionDetails: string
  loaded: boolean
  isPanelOpen: boolean
}

const SuccessExample = () => (
	<MessageBar
		messageBarType={MessageBarType.success}
		isMultiline={false}
	>
	  	Les données ont bien été mis à jour !
	</MessageBar>
)

const ErrorNotification = () => (
	<MessageBar
		messageBarType={MessageBarType.error}
		isMultiline={false}
	>
	  	Une erreur est survenue lors de la récupération / mis à jour des données, veuillez réessayer plus tard !
	</MessageBar>
)

interface Props{
  Id: string
}
function RapportBiCommandBar (props: Props) {
  const dialogStyles = { main: { width: 500 } }
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')
  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Supprimer un rapport',
    closeButtonAriaLabel: 'Fermer',
    subText: 'Voulez-vous vraiment supprimer le rapport ?'
  }
  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles
    }),
    [labelId, subTextId]
  )

  return (
        <>
            <div className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                <CommandButton iconProps={ addIcon } text="Ajouter un rapport" onClick={ () => history.push('/ajouterRapportPowerBi') } />
            </div>
            <div className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                <CommandButton iconProps={ editIcon } text="Modifier un rapport" disabled={props.Id === ''} onClick={ () => history.push('/modifierRapportPowerBi/' + props.Id)} />
            </div>
            <div className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                <CommandButton iconProps={ removeIcon } text="Supprimer un rapport" disabled={props.Id === ''} onClick={toggleHideDialog} />
            </div>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="Annuler" />
                    <DefaultButton onClick={() => _supprimerRapport(props.Id)} text="Supprimer" />
                </DialogFooter>
            </Dialog>
        </>
  )
}

export default class ConfigurationPowerBi extends React.Component<any, IPowerBiConfig> {
  private readonly _selection: Selection
  private _allItems: IRapport[]

  headerItems = {
    title: 'Paramètres / ',
    subtitle: 'Configuration des rapports PowerBi'
  }

  constructor (props: {}) {
    super(props)

    this._allItems = []

    this._selection = new Selection({
      onSelectionChanged: () => this.setState({ selectionDetails: this._getSelectionDetails() })
    })

    this.state = {
      items: [],
      categories: [],
      CategorieDropdownOptions: [],
      columns: [],
      error: false,
      updated: false,
      selectionDetails: this._getSelectionDetails(),
      loaded: false,
      isPanelOpen: true
    }
  }

  async componentDidMount () {
    await adalApiFetch(fetch, '/GetAllCategories', {
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ categories: responseJson })
        this.setState({ CategorieDropdownOptions: this.buildCategorieDropdownOptions() })
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })

    const apiToken = window.localStorage.getItem('apiToken')
    const userId = window.localStorage.getItem('userId')

    await adalApiFetch(fetch, '/GetRapportDetailsByType?type=0', {
      headers: {
        Authorization: 'Bearer ' + apiToken,
        UtilisateurId: userId
      },
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          items: responseJson
        })
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })

    this._allItems = this.state.items

    this.setState({
      columns: [
        {
          key: 'nom', name: 'Nom du rapport', fieldName: 'nom', minWidth: 150, maxWidth: 150, onColumnClick: this._onColumnClick, isResizable: true, isSorted: false, isSortedDescending: false
        },
        {
          key: 'categorie',
          name: 'Catégorie',
          fieldName: 'idCategorie',
          minWidth: 150,
          maxWidth: 150,
          onColumnClick: this._onColumnClick,
          isResizable: true,
          onRender: (item: IRapport) => {
            return (
                        <span>{item.categorie.libelle}</span>
            )
          }
        },
        {
          key: 'ordrePriorite', name: 'Priorité', fieldName: 'ordrePriorite', minWidth: 100, maxWidth: 100, onColumnClick: this._onColumnClick, isResizable: true
        },
        {
          key: 'powerBiWorkspaceId', name: 'Workspace ID', fieldName: 'powerBiWorkspaceId', minWidth: 250, maxWidth: 250, onColumnClick: this._onColumnClick, isResizable: true, isMultiline: true
        },
        {
          key: 'powerBiReportId', name: 'Rapport ID', fieldName: 'powerBiReportId', minWidth: 250, maxWidth: 250, onColumnClick: this._onColumnClick, isResizable: true, isMultiline: true
        },
        {
          key: 'estActif',
          name: 'Actif ?',
          fieldName: 'estActif',
          minWidth: 100,
          maxWidth: 100,
          onColumnClick: this._onColumnClick,
          isResizable: true,
          onRender: (item: IRapport) => {
            return (
                            <span>{item.estActif ? 'Oui' : 'Non'}</span>
            )
          }
        },
        {
          key: 'dateModification',
          name: 'Date de modification',
          fieldName: 'dateModification',
          minWidth: 150,
          maxWidth: 250,
          isSorted: true,
          onColumnClick: this._onColumnClick,
          isResizable: true,
          onRender: (item: IRapport) => {
            return (
                            <span>{_formatDateHeure(item.dateModification)}</span>
            )
          }
        }
      ]
    })

    try {
      (() => {
        const closeButton = document.getElementsByClassName('ms-Panel-closeButton')[0]
        closeButton.addEventListener('click', (e: Event) => this.OpenPanel())
      })()
    } catch (error) {
      console.error(error)
    }

    this.setState({ loaded: true })
  }

  OpenPanel () {
    this.setState({ isPanelOpen: false })
  }

  public render () {
    const { columns, items, CategorieDropdownOptions, loaded, isPanelOpen } = this.state
    const

      menuItems = {
        isActive: true,
        page: 'Parametre',
        subPage: 'configurationPowerBi',
        subMenuList: '',
        isOpen: isPanelOpen
      }

    return (
            <Fabric className="report-page">
                <div className="main-menu">
                    <Menu items={menuItems} />
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    <div className="ms-Grid header">
                        <Header items={this.headerItems} />
                    </div>
                </div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        {loaded
                          ? (
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                <p className="page-content-title less-top-margin"><i>Modifiez les accès de vos groupes aux différents rapports existants.</i></p>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <RapportBiCommandBar Id={this.state.selectionDetails} />
                                    <div className="ms-Grid-col ms-sm4 ms-md3 ms-lg3 report-filter">
                                        <TextField
                                            label="Filtrer par nom"
                                            onChange={this._onFilter}
                                            styles={{ root: { maxWidth: '300px' } }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm4 ms-md3 ms-lg3 report-filter">
                                        <Dropdown
                                            label="Filtrer par catégorie"
                                            placeholder="Choisir une valeur"
                                            options={CategorieDropdownOptions.map(({ key, text }) => { return { key, text } })}
                                            onChange={this._onCategorieFilter}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg12">
                                    <MarqueeSelection selection={this._selection}>
                                        <DetailsList
                                            items={items}
                                            columns={columns}
                                            setKey="set"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            selection={this._selection}
                                            selectionPreservedOnEmptyClick={true}
                                            ariaLabelForSelectionColumn="Toggle selection"
                                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                            checkButtonAriaLabel="Row checkbox"
                                            onItemInvoked={this._onItemInvoked}
                                            className="role-page-list"
                                            selectionMode={SelectionMode.single}
                                            getKey={this._getKey}
                                            isHeaderVisible={true}
                                        />
                                    </MarqueeSelection>
                                </div>
                            </div>
                            )
                          : (
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                        <p className="page-content-title"><i>Modifiez les accès de vos groupes aux différents rapports existants.</i></p>
                                        <Spinner size={SpinnerSize.large} />
                                    </div>
                                </div>
                            </div>
                            )}
                    </div>
                    <div className="notification-bottom">
                        { this.state.updated ? <SuccessExample /> : null }
                        { this.state.error ? <ErrorNotification /> : null }
                    </div>
                </div>
            </Fabric>
    )
  }

  private _onItemInvoked (item?: any, index?: number, ev?: Event) {
    const rapport: IRapport = item as IRapport

    history.push('/modifierRapportPowerBi/' + rapport.id)
  }

  private _getSelectionDetails (): string {
    const selectionCount = this._selection.getSelectedCount()

    switch (selectionCount) {
      case 0:
        return ''
      case 1:
        return (this._selection.getSelection()[0] as IRapport).id
      default:
        return ''
    }
  }

  private _getKey (item: any, index?: number): string {
    return item.key
  }

  private buildCategorieDropdownOptions (): IDropdownOption[] {
    const firstItem = [{
      key: '',
      text: 'Choisir une catégorie'
    }]

    const temp = this.state.categories.map(x => {
      return {
        key: x.id,
        text: x.libelle
      }
    })

    const result = firstItem.concat(temp)

    return result
  }

  private readonly _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state
    const newColumns: IColumn[] = columns.slice()
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0]
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending)
    this.setState({
      columns: newColumns,
      items: newItems
    })
  }

  private readonly _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      items: newValue ? this._allItems.filter(i => i.nom.toLowerCase().includes(newValue.toLowerCase())) : this._allItems
    })
  }

  _onCategorieFilter = (event: any, value: any) => {
    this.setState({
      items: value.key ? this._allItems.filter(i => i.idCategorie === value.key) : this._allItems
    })
  }
}

function _copyAndSort<T> (items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
}

function _supprimerRapport (id: string) {
  const apiToken = window.localStorage.getItem('apiToken')
  const userId = window.localStorage.getItem('userId')

  adalApiFetch(fetch, '/DeleteRapport', {
    headers: {
      Authorization: 'Bearer ' + apiToken,
      UtilisateurId: userId,
      RapportGuid: id
    },
    method: 'delete'
  })
    .then(response => response.json())
    .then(responseJson => {
      history.go(0)
    })
    .catch(error => {
      console.error(error)
    })
}
