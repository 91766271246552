import * as React from 'react'
import '@fluentui/react/lib/Icon'
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons'
import { ReactComponent as HomeIcon } from '../medias/home.svg'
import { ReactComponent as CarIcon } from '../medias/car.svg'
import { ReactComponent as DeliveryTruck } from '../medias/delivery-truck.svg'
import { ReactComponent as Euro } from '../medias/euro.svg'
import { ReactComponent as Statistics } from '../medias/statistics.svg'
import { ReactComponent as Settings } from '../medias/settings.svg'
import { ReactComponent as Profile } from '../medias/profile-user.svg'
import { Link } from 'react-router-dom'
import { Panel } from 'office-ui-fabric-react/lib/Panel'
import { useBoolean } from '@uifabric/react-hooks'
import { Spinner, SpinnerSize } from '@fluentui/react'

initializeIcons()

interface Props{
  items: any
}

export default function Menu (props: Props) {
  const listeDesRoles = JSON.parse(localStorage.getItem('listeRoles') || '[]')
  const listeDesLibelles = listeDesRoles.map((role) => role.libelle)

  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(props.items.isOpen)

  if (props.items.subMenuList === '') {
    props.items.subMenuList =
            <ul>
                <Link to='/role'>
                    <li className={props.items.subPage === 'role' && props.items.isActive ? 'active-item' : ''}>
                        RÔLES
                    </li>
                </Link>
                <Link to='/configurationPowerBi'>
                    <li className={props.items.subPage === 'configurationPowerBi' && props.items.isActive ? 'active-item' : ''}>
                        POWER BI
                    </li>
                </Link>
                {/* TODO : uncomment to make Microstrategy work  */}
                {/* <Link to='/configurationMicroStrategy'>
                    <li className={props.items.subPage === "configurationMicroStrategy" && props.items.isActive ? "active-item" : ""}>
                        MICROSTRATEGY
                    </li>
                </Link> */}
                <Link to='/actualite'>
                    <li className={props.items.subPage === 'actualite' && props.items.isActive ? 'active-item' : ''}>
                        ACTUALITÉS
                    </li>
                </Link>
                <Link to='/support'>
                    <li className={props.items.subPage === 'support' && props.items.isActive ? 'active-item' : ''}>
                        SUPPORT
                    </li>
                </Link>
            </ul>
  }

  // TODO : à confirmer => ce bloc ne doit pas être nécessaire,
  //        car géré par pricing.tsx
  // if (props.items?.page === 'preconisation') {
  //     props.items.subMenuList =
  //         <ul>
  //             <Link to='/pricingremiselineaire'>
  //                 <li>REMISE LINEAIRE</li>
  //             </Link>
  //             <Link to='/pricingremisearticle'>
  //                 <li>REMISE ARTICLE</li>
  //             </Link>
  //         </ul>;
  // }

  return (
        <div className="menu-nav-content">
            <ul onClick={init()}>
                {listeDesRoles.length > 0
                  ? (
                    <li className="accueil-item">
                        <Link to='/accueil'>
                            <HomeIcon className={props.items.page === 'Accueil' && props.items.isActive ? 'active-item' : ''}/>
                        </Link>
                    </li>
                    )
                  : (
                    <></>
                    )}
                {listeDesLibelles.includes('SUPER_ADMIN') || listeDesLibelles.includes('KEY_USER') || listeDesLibelles.includes('SIEGE_ALL') || listeDesLibelles.includes('SIEGE_VL') || listeDesLibelles.includes('ENSEIGNE_VL')
                  ? (
                    <li>
                        <Link to='/pricingvl'>
                            <CarIcon onClick={openPanel} className={props.items.page === 'pricingvl' && props.items.isActive ? 'active-item' : ''}/>
                        </Link>
                    </li>
                    )
                  : listeDesLibelles.includes('UNDEFINED_AD_GROUPE') ? <li><Spinner size={SpinnerSize.medium} className="menu-not-charged" /></li> : <></>}
                {listeDesLibelles.includes('SUPER_ADMIN') || listeDesLibelles?.includes('KEY_USER') || listeDesLibelles.includes('SIEGE_ALL') || listeDesLibelles.includes('SIEGE_PL') || listeDesLibelles.includes('ENSEIGNE_PL')
                  ? (
                    <li id="pricing-menu">
                        <Link to='/pricingpl'>
                            <DeliveryTruck onClick={openPanel} className={props.items.page === 'pricingpl' && props.items.isActive ? 'active-item' : ''}/>
                        </Link>
                    </li>
                    )
                  : listeDesLibelles.includes('UNDEFINED_AD_GROUPE') ? <li><Spinner size={SpinnerSize.medium} className="menu-not-charged" /></li> : <></>}
                {listeDesLibelles.includes('SUPER_ADMIN') || listeDesLibelles.includes('KEY_USER') || listeDesLibelles.includes('SIEGE_ALL') || listeDesLibelles.includes('SIEGE_VL') || listeDesLibelles.includes('SIEGE_PL') || listeDesLibelles.includes('PRICING_T6')
                  ? (
                    <li>
                        <Link to='/preconisation'>
                            <Euro onClick={openPanel} className={props.items.page === 'preconisation' && props.items.isActive ? 'active-item' : ''} />
                        </Link>
                    </li>
                    )
                  : listeDesLibelles.includes('UNDEFINED_AD_GROUPE') ? <li><Spinner size={SpinnerSize.medium} className="menu-not-charged" /></li> : <></>}
                {listeDesLibelles.includes('SUPER_ADMIN') || listeDesLibelles.includes('KEY_USER') || listeDesLibelles.includes('SIEGE_ALL')
                  ? (
                    <li>
                        <Link to='/transverse'>
                            <Statistics onClick={openPanel} className={props.items.page === 'transverse' && props.items.isActive ? 'active-item' : ''}/>
                        </Link>
                    </li>
                    )
                  : listeDesLibelles.includes('UNDEFINED_AD_GROUPE') ? <li><Spinner size={SpinnerSize.medium} className="menu-not-charged" /></li> : <></>}
                {listeDesLibelles.includes('SUPER_ADMIN') || listeDesLibelles.includes('TEST_EVOSEEDS')
                  ? (
                    <li>
                        <Link to={(props.items.subPage === '') || (props.items.subPage === undefined) ? '/role' : '/' + props.items.subPage} onClick={displayPanelList}>
                            <Settings onClick={openPanel} className={props.items.page === 'Parametre' && props.items.isActive ? 'active-item' : ''}/>
                        </Link>
                    </li>
                    )
                  : listeDesLibelles.includes('UNDEFINED_AD_GROUPE') ? <li><Spinner size={SpinnerSize.medium} className="menu-not-charged" /></li> : <></>}

                <li className={ listeDesRoles.length > 0 ? 'profile-item' : 'profile-item less-top-margin-120' }>
                    <Link to="/profile" >
                        <Profile className={props.items.page === 'Profile' && props.items.isActive ? 'active-item' : ''}/>
                    </Link>
                </li>
            </ul>
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm11">
                        <Panel
                            className="menu-panel"
                            isBlocking={false}
                            isOpen={isPanelOpen}
                            onDismiss={dismissPanel}
                            closeButtonAriaLabel="Fermer"
                            hasCloseButton={true}
                            isHiddenOnDismiss={true}
                            id="menu-panel-id"
                        >
                            <h1 className="panel-title">{props.items.titreMenu !== undefined ? props.items.titreMenu : 'Paramètres'}</h1>
                            {props.items.subMenuList}
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
  )

  function init (): any {
    const app = document.getElementById('App')
    if (props.items.isOpen) {
      app?.classList.add('panel-margin')
    } else {
      app?.classList.remove('panel-margin')
    }
  }

  function displayPanelList (): any {
    props.items.isOpen = true
    props.items.titreMenu = 'Paramètres'
    props.items.page = 'parametres'
    props.items.isActive = true
    props.items.subMenuList =
            <ul>
                <Link to='/role'>
                    <li className={props.items.subPage === 'role' && props.items.isActive ? 'active-item' : ''}>
                        RÔLES
                    </li>
                </Link>
                <Link to='/configurationPowerBi'>
                    <li className={props.items.subPage === 'configurationPowerBi' && props.items.isActive ? 'active-item' : ''}>
                        POWER BI
                    </li>
                </Link>
                {/* TODO : uncomment to make Microstrategy work  */}
                {/* <Link to='/configurationMicroStrategy'>
                    <li className={props.items.subPage === "configurationMicroStrategy" && props.items.isActive ? "active-item" : ""}>
                        MICROSTRATEGY
                    </li>
                </Link> */}
                <Link to='/actualite'>
                    <li className={props.items.subPage === 'actualite' && props.items.isActive ? 'active-item' : ''}>
                        ACTUALITÉS
                    </li>
                </Link>
                <Link to='/support'>
                    <li className={props.items.subPage === 'support' && props.items.isActive ? 'active-item' : ''}>
                        SUPPORT
                    </li>
                </Link>
            </ul>
  }
}
