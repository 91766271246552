import {
  Fabric
  , IIconProps
  , PrimaryButton
  , TextField
  , MessageBar
  , MessageBarType
  , Spinner
  , SpinnerSize
} from '@fluentui/react'
import * as React from 'react'
import { adalApiFetch } from '../../adalConfig'
import Header from '../Header'
import Menu from '../Menu'

interface ISupportConfig {
  id: string
  estActif: boolean
  textePresentation: string
  emailSupport: string
  sujet: string
  idActualite: string
  dateCreation: Date
  utilisateurCreation: string
  dateModification: Date
  utilisateurModification: string
  updated: boolean
  error: boolean
  loaded: boolean
  isPanelOpen: boolean
}

const SuccessNotification = () => (
	<MessageBar
		messageBarType={MessageBarType.success}
		isMultiline={false}
	>
	  	Les données ont bien été mis à jour !
	</MessageBar>
)

const ErrorNotification = () => (
	<MessageBar
		messageBarType={MessageBarType.error}
		isMultiline={false}
	>
	  	Une erreur est survenue lors de la récupération / mis à jour des données, veuillez réessayer plus tard !
	</MessageBar>
)

export default class Support extends React.Component<any, ISupportConfig> {
  headerItems = {
    title: 'Paramètres / ',
    subtitle: 'Support'
  }

  saveIcon: IIconProps = { iconName: 'Save' }

  constructor (props: any) {
    super(props)

    this.state = {
      id: '',
      emailSupport: '',
      sujet: '',
      estActif: false,
      dateCreation: new Date(),
      utilisateurCreation: '',
      dateModification: new Date(),
      utilisateurModification: '',
      idActualite: '',
      textePresentation: '',
      updated: false,
      error: false,
      loaded: false,
      isPanelOpen: true
    }
  }

  async componentDidMount () {
    await adalApiFetch(fetch, '/GetConfSupport', {
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          id: responseJson.id,
          estActif: responseJson.estActif,
          textePresentation: responseJson.textePresentation,
          emailSupport: responseJson.emailSupport,
          sujet: responseJson.sujet
        })
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })

    try {
      (() => {
        const closeButton = document.getElementsByClassName('ms-Panel-closeButton')[0]
        closeButton.addEventListener('click', (e: Event) => this.OpenPanel())
      })()
    } catch (error) {
      console.error(error)
    }

    this.setState({ loaded: true })
  }

  OpenPanel () {
    this.setState({ isPanelOpen: false })
  }

  submitHandler = (event: any) => {
    event.preventDefault()

    const data = {
      id: this.state.id,
      emailSupport: this.state.emailSupport,
      sujet: this.state.sujet,
      dateModification: new Date(),
      textePresentation: this.state.textePresentation
    }

    adalApiFetch(fetch, '/UpdateConfAccueil', {
      headers: { 'Content-Type': 'application/json' },
      method: 'put',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson) {
          this.setState({ updated: true, error: false })
          setTimeout(() => {
            this.setState({ updated: false })
          }, 5000)
        } else {
          this.setState({ error: true, updated: false })
          setTimeout(() => {
            this.setState({ error: false })
          }, 5000)
        }
      })
      .catch(error => {
        this.setState({ error: true, updated: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })
  }

  render () {
    const { textePresentation, emailSupport, sujet, loaded, isPanelOpen } = this.state
    const

      menuItems = {
        isActive: true,
        page: 'Parametre',
        subPage: 'support',
        isOpen: isPanelOpen,
        subMenuList: ''
      }

    return (
            <form onSubmit={this.submitHandler}>
                <Fabric>
                    <div className="main-menu">
                        <Menu items={menuItems} />
                    </div>
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <div className="ms-Grid header">
                            <Header items={this.headerItems} />
                        </div>
                    </div>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            {loaded
                              ? (
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                    <p className="page-content-title less-top-margin"><i>Paramétrez et modifiez les informations rattachées au contact support.</i></p>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="support-input-row">
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <label>Email de contact</label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                                <TextField
                                                    value = {emailSupport}
                                                    onChange={this.onChangeMailSupport}
                                                />
                                            </div>
                                        </div>
                                        <div className="support-input-row">
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <label>Sujet de l'Email</label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                                <TextField
                                                    value = {sujet}
                                                    onChange={this.onChangeSujet}
                                                />
                                            </div>
                                        </div>
                                        <div className="support-input-row">
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <label>Texte d'accueil</label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                            <TextField
                                                    multiline
                                                    rows={8}
                                                    name="textePresentation"
                                                    value = {textePresentation}
                                                    onChange={this.onChangeHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-col ms-lg12 support-save">
                                            <PrimaryButton
                                                text="Enregistrer"
                                                type="submit"
                                                iconProps={this.saveIcon}
                                            />
                                        </div>
                                    </div>
                                </div>
                                )
                              : (
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                            <p className="page-content-title"><i>Modifiez les accès de vos groupes aux différents rapports existants.</i></p>
                                            <Spinner size={SpinnerSize.large} />
                                        </div>
                                    </div>
                                </div>
                                )}
                        </div>
                        <div className="notification-bottom">
                            { this.state.updated ? <SuccessNotification /> : null }
                            { this.state.error ? <ErrorNotification /> : null }
                        </div>
                    </div>
                </Fabric>
            </form>
    )
  }

  onChangeMailSupport = (event: any, value: any) => {
    this.setState({ emailSupport: value })
  }

  onChangeSujet = (event: any, value: any) => {
    this.setState({ sujet: value })
  }

  onChangeHandler = (event: any, value: any) => {
    let stateName
    let stateValue

    stateName = event.target.name
    stateValue = value

    this.setState({ ...this.state, [stateName]: stateValue })
  }
}
