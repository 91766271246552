import 'office-ui-fabric-react/dist/css/fabric.css'
import './fonts/futura-light-bt.ttf'
// import './index.css';
import { runWithAdal } from 'react-adal'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { authContext, adalConfig } from './adalConfig'
import History from './components/History'

const DO_NOT_LOGIN = false

runWithAdal(authContext, async () => {
  const userCached = authContext.getCachedUser()

  if (userCached) {
    // FOR DEBUG ONLY =>
    // console.log('userCached: ');
    // console.log(userCached);
    authContext.config.extraQueryParameter = 'login_hint=' + userCached.userName
  }

  if (window.localStorage.getItem('apiToken') === null) {
    const apiToken = authContext.getCachedToken(adalConfig.clientId)

    // FOR DEBUG ONLY =>
    // console.log('apiToken: ' + apiToken);
    if (apiToken) {
      const isValidated = checkTokenIsValid(apiToken)
      if (isValidated) {
        await window.localStorage.setItem('apiToken', apiToken)
      } else {
        console.log('Invalid token => tenant not authorized')
        require('./components/Erreur.tsx')
      }
    } else {
      require('./components/Erreur.tsx')
    }
  }

  if (window.localStorage.getItem('graphApiToken') === null) { // Si le Graph API Token n'est pas récupéré, donc forcement la liste des roles est null
    window.localStorage.setItem('listeRoles', '[{"id":-1,"libelle":"UNDEFINED_AD_GROUPE","nomGroupeAd":"UNDEFINED_AD_GROUPE","groupeAdId":"UNDEFINED_AD_GROUPE","rapportsRoleAcces":[]}]')
    await loadAuthentication()
  }

  if (authContext.isCallback(window.location.hash)) {
    authContext.handleWindowCallback()
  } else {
    require('./App.tsx')
  }
}, DO_NOT_LOGIN)

async function loadAuthentication () {
  await authContext.acquireToken(adalConfig.endpoints.graphApiUri, async function (error, graphApiToken) {
    if (error || !graphApiToken) {
      window.localStorage.removeItem('graphApiToken')
      console.error('ADAL error occurred: ' + error)
      History.go(0)
    } else {
      // FOR DEBUG ONLY =>
      // console.log('graphApiToken:' + graphApiToken);
      window.localStorage.setItem('graphApiToken', graphApiToken)

      const url = 'https://graph.microsoft.com/v1.0/me'
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('graphApiToken')
        },
        redirect: 'follow'
      })
        .then(async response => await response.json())
        .then(result => {
          if (result.id !== undefined) {
            window.localStorage.setItem('userId', result.id)
            getAdRole(result.id)
          }
        })
        .catch(function () {
          console.error('Fetching membership of ad group failed.')
          require('./components/Erreur.tsx')
        })
    }
  })
}

function checkTokenIsValid (token: any) {
  // On vérifie si l'issuer du token appartient aux tenants autorisés
  const decodedToken = jwt_decode<JwtPayload>(token)
  let isValidated = false
  adalConfig.allowedTenants.forEach((tenant) => {
    if ((decodedToken.iss?.includes(tenant.Value)) ?? false) {
      isValidated = true
    }
  })

  return isValidated
}

function getAdRole (resultId: string) {
  console.log('getAdRole called')

  const apiToken = window.localStorage.getItem('apiToken')

  fetch(adalConfig.apiUrl + '/GetAdRole', {
    headers: {
      Authorization: 'Bearer ' + apiToken,
      UtilisateurId: resultId
    },
    method: 'get'
  })
    .then(async response => await response.text())
    .then(resultat => {
      if (isJson(resultat)) {
        window.localStorage.setItem('listeRoles', resultat)
      } else {
        window.localStorage.setItem('listeRoles', '[]')
      }

      History.go(0)
    })
    .catch(error => {
      console.error(error)
      require('./components/Erreur.tsx')
    })
}

function isJson (str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
