import * as React from 'react'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'

export interface IEditPresetDialog {
  onEdit: () => void
  onClose: () => void
}

const modalPropsStyles = { main: { maxWidth: 450 } }
const dialogContentProps = {
  onClick: DialogType.normal,
  title: 'Do you want to overwrite parameter?'
}

export const EditPresetDialog: React.FunctionComponent<IEditPresetDialog> = props => {
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles
    }),
    [])

  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={() => props.onClose()}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={() => props.onEdit()} text="Save" />
          <DefaultButton onClick={() => props.onClose()} text="Dismiss" />
        </DialogFooter>
      </Dialog>
    </>
  )
}
