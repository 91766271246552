import * as React from 'react'
import {
  MessageBarType,
  MessageBar,
  getTheme,
  mergeStyleSets
} from '@fluentui/react'

const theme = getTheme()
const { fonts } = theme
const classNames = mergeStyleSets({
  messageBar: [
    fonts.medium,
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  ]
})

interface IMessageBarProps {
  description: string
  messageBarType: MessageBarType
}

const DefaultExample = (props: IMessageBarProps): React.ReactElement => (
  <MessageBar>
    <div className={classNames.messageBar}>{props.description}</div>
  </MessageBar>
)

const ErrorExample = (props: IMessageBarProps): React.ReactElement => (
  <MessageBar messageBarType={MessageBarType.error} >
    <div className={classNames.messageBar}>{props.description}</div>
  </MessageBar>
)

const SuccessExample = (props: IMessageBarProps): React.ReactElement => (
  <MessageBar messageBarType={MessageBarType.success} >
    <div className={classNames.messageBar}>{props.description}</div>
  </MessageBar>
)

export const MessageBarBasic: React.FunctionComponent<IMessageBarProps> = props => {
  return (
    <div>
        {(props.messageBarType === MessageBarType.info) && <DefaultExample description={props.description} messageBarType={props.messageBarType} />}
        {(props.messageBarType === MessageBarType.error) && <ErrorExample description={props.description} messageBarType={props.messageBarType} />}
        {(props.messageBarType === MessageBarType.success) && <SuccessExample description={props.description} messageBarType={props.messageBarType} />}
    </div>
  )
}
