import { CompoundButton, Fabric, IIconProps, MessageBar, MessageBarType } from '@fluentui/react'
import * as React from 'react'
import { adalApiFetch } from '../../adalConfig'
import Header from '../Header'
import Menu from '../Menu'
import Truncate from 'react-truncate-html'
import Speaker from '../../medias/loud-speaker.svg'
import History from '../History'

interface IActualiteConfig {
  id: string
  startDate: Date | undefined
  dateRedaction: Date
  doitAfficher: boolean
  contenu: string
  dateCreation: Date
  dateModification: Date
  utilisateurCreation: string
  utilisateurModification: string
  upserted: boolean
  error: boolean
}

const SuccessExample = () => (
	<MessageBar
		messageBarType={MessageBarType.success}
		isMultiline={false}
	>
	  	Les données ont bien été mis à jour !
	</MessageBar>
)

const ErrorNotification = () => (
	<MessageBar
		messageBarType={MessageBarType.error}
		isMultiline={false}
	>
	  	Une erreur est survenue lors de la récupération / mis à jour des données, veuillez réessayer plus tard !
	</MessageBar>
)

export default class ActualiteContent extends React.Component<any, IActualiteConfig> {
  headerItems = {
    title: 'Actualité / ',
    subtitle: 'Contenu'
  }

  menuItems = {
    isActive: true,
    page: 'Accueil',
    subPage: '',
    isOpen: false,
    subMenuList: ''
  }

  saveIcon: IIconProps = { iconName: 'Save' }

  constructor (props: any) {
    super(props)

    this.state = {
      id: '',
      dateRedaction: new Date(),
      contenu: '',
      doitAfficher: false,
      dateCreation: new Date(),
      utilisateurCreation: '',
      dateModification: new Date(),
      utilisateurModification: '',
      startDate: new Date(Date.now()),
      upserted: false,
      error: false
    }
  }

  async componentDidMount () {
    await adalApiFetch(fetch, '/GetSettingActualite', {
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          id: responseJson.id,
          dateRedaction: new Date(responseJson.dateRedaction),
          doitAfficher: responseJson.doitAfficher,
          contenu: responseJson.contenu
        })
      })
      .catch(error => {
        this.setState({ error: true, upserted: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })
  }

  render () {
    const { contenu, dateRedaction } = this.state
    return (
                <Fabric>
                    <div className="main-menu">
                        <Menu items={this.menuItems} />
                    </div>
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <div className="ms-Grid header">
                            <Header items={this.headerItems} />
                        </div>
                    </div>
                    <div className="ms-Grid actualite-content-page" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-row accueil-page-feed ms-sm11 ms-lg11">
                                <div className="feed-title">
                                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                        <img alt="Actualite" className="feed-title-icon" src={Speaker} width="25px" />
                                    </div>
                                    <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                                        <b className="feed-title">Actualité</b>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 feed-date">
                                        <b>{this._onFormatDate(new Date(dateRedaction))}</b>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                                    <p className="feed-content">
                                        <Truncate
                                            lines={300}
                                            dangerouslySetInnerHTML={{
                                              __html: contenu
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br></br>
                            <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                            <CompoundButton primary onClick={() => History.push('/accueil')} className="actualite-retour-btn">
                                Retourner à la page d'accueil
                            </CompoundButton>
                            </div>
                        </div>
                        <div className="notification-bottom">
                            { this.state.upserted ? <SuccessExample /> : null }
                            { this.state.error ? <ErrorNotification /> : null }
                        </div>
                    </div>
                </Fabric>
    )
  }

  private readonly _onFormatDate = (date: Date | undefined): string => {
    return date!.getDate() + '/' + (date!.getMonth() + 1) + '/' + date!.getFullYear()
  }
}
