import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal'

const envconfig = require('./envconfig.json')

export const adalConfig = {
  authority: 'https://login.microsoftonline.com/' + envconfig.tenant,
  tenant: envconfig.tenant,
  clientId: envconfig.clientId,
  endpoints: {
    api: envconfig.clientId,
    graphApiUri: envconfig.graphApiUri
  },
  apiUrl: envconfig.apiUrl,
  redirectUri: envconfig.redirectUrl,
  allowedTenants: envconfig.allowedTenants
}

export const authContext = new AuthenticationContext(adalConfig)

export const authFetch = async url => {
  const token = await authContext.getCachedToken
  return await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  })
}

export const authToken = authContext.getCachedToken

export const adalApiFetch = async (fetch: any, url: any, options: any) =>
  await adalFetch(authContext, adalConfig.endpoints.api, fetch, adalConfig.apiUrl + url, options)

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api)

export function sendRapportError (reportId, date) {
  // const connectedUserName = (authContext.getCachedUser().profile.name).split(' ')[0];
  const userName = authContext.getCachedUser().userName
  const data = {
    idRapport: reportId,
    nomRapport: '',
    idUser: localStorage.getItem('userId') || '',
    userName,
    startDate: date
  }

  const apiToken = window.localStorage.getItem('apiToken')
  const userId = window.localStorage.getItem('userId')

  adalApiFetch(fetch, '/AddRapportError', {
    headers: {
      Authorization: 'Bearer ' + apiToken,
      UtilisateurId: userId,
      'Content-Type': 'application/json'
    },
    method: 'post',
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.status !== 200) { console.error(response) }
    })
    .catch(error => {
      console.error(error)
    })
}
