import React, { FormEvent } from 'react'
import {
  Fabric,
  getTheme,
  IDropdownOption,
  IStackTokens,
  ITag,
  MessageBarType,
  PrimaryButton,
  Separator,
  Spinner,
  Stack,
  TextField
} from '@fluentui/react'
import { Dropdown, IDropdownStyles } from '@fluentui/react/lib/Dropdown'
import { v4 as uuidv4 } from 'uuid'
import Header from '../Header'
import { adalApiFetch } from '../../adalConfig'

import '@ag-grid-community/core/dist/styles/ag-grid.css'
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css'
import { formatCsvNumber, formatCsvPercentNumber, getCurrentDate, pickerFtvSuggestionsProps, pickerGrvSuggestionsProps, pickerMarqueSuggestionsProps, pricingClassNames, tarifOptions, coefOptions, coefPercentOptions } from './pricingRemiseConfig'
import GenericPricingRemisePicker from './genericPricingRemisePicker'
import PricingRemiseArticlesResult from './pricingRemiseArticlesResult'
import { loadCodesReferentiels } from './pricingShared'
import { PreconisationMenuItems } from '../shared/preconisationMenuItems'
import { PricingRemiseLineaireDetails } from '../shared/pricingRemiseLineaireDetails'
import Serializable from '../interface/Serializable'
import { RemiseLineaireParameters } from './pricingRemiseLineaireParameters'
import { MessageBarBasic } from '../shared/infoTab'
import { SavePresetDialog } from '../shared/savePresetDialog'

const theme = getTheme()

const stackTokens: IStackTokens = {
  childrenGap: 8,
  padding: 0
}

export interface ISettingPricingLineaire {
  key?: string
  grv?: string | null | undefined
  cdm?: string | null | undefined
  ftv?: string | null | undefined
  tdb?: string
  tx?: number
  tptx?: string
  coef?: number
  coefType?: string
}

export class SettingPricingLineaire implements Serializable<SettingPricingLineaire>, ISettingPricingLineaire {
  key?: string
  grv?: string | null | undefined
  cdm?: string | null | undefined
  ftv?: string | null | undefined
  tdb?: string
  tx?: number
  tptx?: string
  coef?: number
  coefType?: string

  public get displayedCoef (): string | undefined {
    return `${this.coef!} ${this.coefType!}`
  }

  public get displayedCoefBase (): string | undefined {
    return `${this.tx!} ${this.tptx!}`
  }

  deserialize (input: Record<string, unknown>): SettingPricingLineaire {
    this.key = input.id as string
    this.grv = input.grv as string
    this.cdm = input.marque as string
    this.ftv = input.ftv as string
    this.tdb = input.typeTarif as string
    this.tx = input.coefBase as number
    this.tptx = input.coefBaseType as string
    this.coef = input.coef as number
    this.coefType = input.coefType as string
    return this
  }

  create (grv: string, marque: string, ftv: string, tarifType: string, coefBaseType: string, coefBase: string, coef: number, coefType: string): SettingPricingLineaire {
    this.key = uuidv4()
    this.grv = grv
    this.cdm = marque
    this.ftv = ftv
    this.tdb = tarifType
    this.tptx = coefBaseType
    this.tx = Number.isNaN(Number.parseFloat(coefBase)) ? 0 : Number.parseFloat(Number.parseFloat(coefBase).toFixed(2))
    this.coef = coef
    this.coefType = coefType
    return this
  }
}

export interface IArticlePricingLineaire {
  key: string
  grv: string
  cdm: string
  ftv: string
  cdfm: string
  n: string
  cdref: string
  cdrefpre: string
  pxbase: number
  pxosc: number
  pxbasecat: string
  pxachat: number
  pxt6: number
  pxt6m1: number
  pxt6manual: number
  txrm: number
  txmgt6: number
  txrmm1: number
  txmgt6m1: number
  qtevte: number
  qtevte12m: number
  txrmppv: number
  txrmppvm1: number
  mtpxfrcav: number
  mtpxfrcap: number
  ctfrcg: number
  caavnt: number
  evol_tx_mg: number
  tptx: string
  tx: number
  txdct: number
}

interface IPricingRemiseLineaireConfig {
  error: boolean
  pricingSettingsRowsData: ISettingPricingLineaire[]
  selectedGrv: string | null | undefined
  selectedFtv: string | null | undefined
  selectedMarque: string | null | undefined
  selectedTarifType: string
  selectedCoefBaseType: string
  selectedCoefBase: string
  selectedCoef: number
  selectedCoefType: string
  codesReferentiel: any
  articles: IArticlePricingLineaire[]
  articlesColumnsDefs: any
  isBusy: boolean
  parameterId: number | null
  editedParameter: RemiseLineaireParameters | null
  shouldShowUploadPopup: boolean
}

export default class PricingRemiseLineaire extends React.Component<any, IPricingRemiseLineaireConfig> {
  headerItems = {
    title: 'Remise Lineaire',
    subtitle: ''
  }

  grvPicker: any
  ftvPicker: any
  marquePicker: any
  apiToken: any
  userId: any

  constructor (props: any) {
    super(props)
    this.grvPicker = React.createRef()
    this.ftvPicker = React.createRef()
    this.marquePicker = React.createRef()
    this.apiToken = window.localStorage.getItem('apiToken')
    this.userId = window.localStorage.getItem('userId')

    const idParameter = new URLSearchParams(this.props.location.search).get('id')
    this.state = {
      parameterId: (idParameter !== null && !isNaN(parseInt(idParameter))) ? parseInt(idParameter) : null,
      editedParameter: null,
      error: false,
      pricingSettingsRowsData: [],
      codesReferentiel: null,
      articlesColumnsDefs: [
        { key: 'grv', name: 'GRV', className: pricingClassNames.defaultCell, fieldName: 'grv', minWidth: 320, isResizable: true },
        { key: 'cdm', name: 'Marque', className: pricingClassNames.defaultCell, fieldName: 'cdm', minWidth: 35, maxWidth: 35, isResizable: true, isMultiline: true },
        { key: 'ftv', name: 'FTV', className: pricingClassNames.defaultCell, fieldName: 'ftv', minWidth: 220, isResizable: true, isMultiline: true },
        { key: 'n', name: 'Nom Article', className: pricingClassNames.defaultCell, fieldName: 'n', minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
        { key: 'cdref', name: 'Code Réf.', className: pricingClassNames.defaultCell, fieldName: 'cdref', minWidth: 100, isResizable: true },
        { key: 'cdrefpre', name: 'Cross Réf. Prem.', className: pricingClassNames.defaultCell, fieldName: 'cdrefpre', minWidth: 50, maxWidth: 50, isResizable: true },
        { key: 'pxosc', name: 'Px Oscaro', className: pricingClassNames.defaultRightCell, fieldName: 'pxosc', minWidth: 50, maxWidth: 50, isResizable: true },
        { key: 'pxbasecat', name: 'Type Tarif', className: pricingClassNames.defaultRightCell, fieldName: 'pxbasecat', minWidth: 50, maxWidth: 50, isResizable: true },
        { key: 'pxbase', name: 'Tarif de base', className: pricingClassNames.defaultRightCell, fieldName: 'pxbase', minWidth: 50, maxWidth: 50, isResizable: true },
        { key: 't6', name: 'Valeur T6', className: pricingClassNames.defaultRightCell, fieldName: 'pxt6', minWidth: 60, maxWidth: 60, isResizable: true },
        {
          key: 'manualt6',
          name: 'T6 manuel',
          className: pricingClassNames.defaultRightCell,
          fieldName: 'pxt6manual',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
          onRender: (item: IArticlePricingLineaire) => {
            return <TextField
                                    type="number"
                                    borderless
                                    underlined
                                    className={pricingClassNames.inputNumberRight}
                                    inputClassName={pricingClassNames.alignRight}
                                    defaultValue={item.pxt6?.toString()}
                                    onChange={(e, i) => this.onT6ManualValueChanged(item, e, i)}/>
          }
        },
        { key: 'txrm', name: 'Tx Rem.', className: pricingClassNames.defaultRightCell, fieldName: 'txrm', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'txmgt6', name: 'Tx marge T6', className: pricingClassNames.defaultRightCell, fieldName: 'txmgt6', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'txrmppv', name: 'Tx Rem. Pond. / Vol.', className: pricingClassNames.defaultRightCell, fieldName: 'txrmppv', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'txdct', name: 'Tx décote', className: pricingClassNames.defaultRightCell, fieldName: 'txdct', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'pxt6m1', name: 'T6 M-1', className: pricingClassNames.defaultRightCell, fieldName: 'pxt6m1', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'txrmm1', name: 'Tx de Rem. M-1', className: pricingClassNames.defaultRightCell, fieldName: 'txrmm1', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'txmgt6m1', name: 'Tx de Marge T6 M-1', className: pricingClassNames.defaultRightCell, fieldName: 'txmgt6m1', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'txrmppvm1', name: 'Tx Rem. Pond. / vol. M-1', className: pricingClassNames.defaultRightCell, fieldName: 'txrmppvm1', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'qtevte', name: 'Qté M1', className: pricingClassNames.defaultRightCell, fieldName: 'qtevte', minWidth: 60, maxWidth: 60, isResizable: true },
        { key: 'evol_tx_mg', name: 'Evol tx marge', className: pricingClassNames.defaultRightCell, fieldName: 'evol_tx_mg', minWidth: 60, maxWidth: 60, isResizable: true }
      ],
      articles: [],
      selectedGrv: null,
      selectedFtv: null,
      selectedMarque: null,
      selectedTarifType: 'T3',
      selectedCoefBaseType: '%',
      selectedCoefBase: '0',
      selectedCoef: 0,
      selectedCoefType: '%',
      isBusy: false,
      shouldShowUploadPopup: false
    }
  }

  async componentDidMount (): Promise<void> {
    this.setState({ isBusy: true })

    if (this.state.parameterId !== null) {
      await adalApiFetch(fetch, `/pricing-remise/remise-lineaire/parameter/${this.state.parameterId}`, { method: 'get' })
        .then(response => {
          if (response.status === 404) {
            alert(`Fetching stored parameter failed. ${this.state.parameterId ?? ''} not found`)
            this.setState({ parameterId: null })
          } else if (response.status !== 200) {
            alert(`Fetching stored parameter failed. Unexpected server code ${response.status as string ?? ''}`)
            this.setState({ parameterId: null })
          }
          return response
        })
        .then(response => response.json())
        .then(result => {
          const parameters = new RemiseLineaireParameters().deserialize(result)
          this.setState({ editedParameter: parameters, pricingSettingsRowsData: parameters.remiseParameters ?? [] })
        })
        .catch(error => {
          console.log(error)
          alert(`Fetching stored parameter ${this.state.parameterId ?? ''} failed`)
          this.setState({ parameterId: null })
        })
    }

    await loadCodesReferentiels(
      (codes) => this.setState({
        codesReferentiel: codes
      }),
      () => {
        this.setState({ error: true })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
      }
    )

    this.setState({ isBusy: false })
  }

  onT6ManualValueChanged = (item: IArticlePricingLineaire, event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | null | undefined): void => {
    const t6ManualValue = (newValue != null && newValue != undefined) ? +newValue : 0

    const t6ValueNewVal = t6ManualValue.toFixed(2)

    item.pxt6manual = +t6ValueNewVal

    const tarifDeBase = item.tptx === '%' ? item.pxbase * (100 - item.tx) / 100 : item.pxbase + item.tx

    item.txrm = item.pxt6manual === 0 ? 0 : +(((tarifDeBase - item.pxt6manual) * 100) / tarifDeBase).toFixed(2)

    item.txmgt6 = item.pxt6manual === 0 ? 0 : +(((item.pxt6manual - item.pxachat) * 100) / item.pxt6manual).toFixed(2)

    if (item.pxt6manual === 0 || item.qtevte12m === 0) {
      item.txrmppv = 0
    } else {
      item.txrmppv = +((((item.pxbase * item.qtevte12m) - (item.pxt6manual * item.qtevte12m)) * 100) / ((item.pxt6manual * item.qtevte12m))).toFixed(2)
    }

    if (item.pxachat === 0) {
      item.evol_tx_mg = 0
    } else {
      item.evol_tx_mg = +(item.txmgt6 - item.txmgt6m1).toFixed(2)
    }

    const index = this.state.articles.findIndex(a => a.key === item.key)
    const tempArticles = [...this.state.articles]
    tempArticles[index] = item
    this.setState({ articles: tempArticles })
  }

  render () {
    const _onSettingsItemInvoked = (item: SettingPricingLineaire): void => {
      const settings = this.state.pricingSettingsRowsData.filter(function (s) {
        return s.key != item.key
      })

      this.setState({ pricingSettingsRowsData: settings })
    }

    const _onDeleteIconButtonClicked = (item): void => {
      _onSettingsItemInvoked(item)
    }

    const _addFilter = (): void => {
      if (this.state.selectedGrv == null &&
                this.state.selectedMarque == null &&
                this.state.selectedFtv == null
      ) {
        alert("L'un des trois critères GRV, Marque ou FTV doit être rempli.")
        return
      }

      if (this.state.selectedCoefBase == null || this.state.selectedCoef == null) {
        alert('Tous les critères doivent être remplis')
        return
      }

      this.setState({
        pricingSettingsRowsData: [...this.state.pricingSettingsRowsData, new SettingPricingLineaire()
          .create(this.state.selectedGrv!,
            this.state.selectedMarque!,
            this.state.selectedFtv!,
            this.state.selectedTarifType,
            this.state.selectedCoefBaseType,
            this.state.selectedCoefBase,
            this.state.selectedCoef,
            this.state.selectedCoefType)]
      })

      this.grvPicker.current.resetSelectedItems()
      this.ftvPicker.current.resetSelectedItems()
      this.marquePicker.current.resetSelectedItems()

      this.setState({
        selectedGrv: null,
        selectedFtv: null,
        selectedMarque: null,
        selectedTarifType: 'T3',
        selectedCoefBaseType: '%',
        selectedCoefBase: '0',
        selectedCoef: 0,
        selectedCoefType: '%'
      })
    }

    const _calculate = async (): Promise<void> => {
      if (this.state.pricingSettingsRowsData.length === 0) {
        alert('Ajouter un paramétrage pour procéder au calcul.')
        return
      }

      this.setState({ articles: [], isBusy: true })

      adalApiFetch(fetch, '/pricing-remise/remise-lineaire', {
        headers: {
          Authorization: 'Bearer ' + this.apiToken,
          UtilisateurId: this.userId,
          'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(this.state.pricingSettingsRowsData)
      })
        .then(response => response.json())
        .then(responseJson => {
          const articlesWithKey = responseJson.map(item => ({ ...item, key: uuidv4() }))
          if (articlesWithKey.length > 35000) {
            alert('La recherche a remonté plus de 35 000 articles, veuillez limiter le nombre de paramètres.')
          }
          this.setState({
            articles: articlesWithKey
          })
        })
        .catch(error => {
          alert('Les paramètres sélectionnés ne remontent aucun résultat.')
          this.setState({ error: true })
          setTimeout(() => {
            this.setState({ error: false })
          }, 5000)
          console.error(error)
        })
        .finally(() => {
          this.setState({ isBusy: false })
        })
    }

    const onUploadClicked = (): void => {
      this.setState({ shouldShowUploadPopup: true })
    }

    const onUploadConfirmed = async (name: string): Promise<void> => {
      this.setState({ isBusy: true, shouldShowUploadPopup: false })
      await adalApiFetch(fetch, '/pricing-remise/remise-lineaire/snapshot', {
        headers: {
          Authorization: 'Bearer ' + this.apiToken,
          'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify({ name, parameterId: this.state.parameterId, list: this.state.articles })
      })
        .catch(error => {
          alert('Upload failed')
          console.error(error)
        })
        .finally(() => {
          this.setState({ isBusy: false })
        })
    }

    const dropdownStyles: Partial<IDropdownStyles> = {
      dropdown: { width: 70 }
    }

    const codesGrv: ITag[] = this.state.codesReferentiel?.codesGrv.map(item => ({ key: uuidv4(), name: item }))
    const codesFtv: ITag[] = this.state.codesReferentiel?.codesFtv.filter(item => item != null).map(item => ({ key: uuidv4(), name: item }))
    const codesMarque: ITag[] = this.state.codesReferentiel?.codesMarque.filter(item => item != null).map(item => ({ key: uuidv4(), name: item }))

    const onTarifTypeChanged = (event: FormEvent<HTMLDivElement>, item: IDropdownOption | undefined, index?: number | undefined): void => {
      this.setState({ selectedTarifType: item!.key.toString() })
    }

    const onCoefBaseCatChanged = (event: FormEvent<HTMLDivElement>, item: IDropdownOption | undefined, index?: number | undefined): void => {
      this.setState({ selectedCoefBaseType: item!.key.toString() })
    }

    const onCoefTypeChanged = (event: FormEvent<HTMLDivElement>, item: IDropdownOption | undefined, index?: number | undefined): void => {
      this.setState({ selectedCoefType: item!.key.toString() })
    }

    const onCoefBaseValueChanged = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | null | undefined): void => {
      if (newValue != null && newValue !== undefined) {
        this.setState({ selectedCoefBase: newValue })
      }
    }

    const onCoefValueChanged = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | null | undefined): void => {
      let coefValue = (newValue != null && newValue !== undefined) ? +newValue : 0
      if (coefValue <= 0) coefValue = 0

      const coefValueNewVal = coefValue.toFixed(2)

      this.setState({ selectedCoef: +coefValueNewVal })
    }

    const csvHeaders = [
      'GRV;Marque;FTV;Nom Article;Code Réf.;Code Réf. Prem.;Px Oscaro;Type Tarif;Tarif de base;T6;T6 Manuel;Tx Rem.;Tx marge T6;Tx Rem. Pond. / Vol.;Tx décote;T6 M-1;Tx de Rem. M-1;Tx de Marge T6 M-1;Tx Rem. Pond. / vol. M-1;Qté M1;Evol tx marge'.toUpperCase()
    ]

    const csvFileName = `pricing-remise-linaire${getCurrentDate()}.csv`

    const getArticlesAsCsvFormats = (): void => {
      return this.state.articles.reduce((acc: any, article) => {
        const {
          grv, cdm, ftv, n, cdref, cdrefpre, pxosc,
          pxbasecat, pxbase, pxt6, pxt6manual,
          txrm, txmgt6, txrmppv,
          txdct, pxt6m1, txrmm1,
          txmgt6m1, txrmppvm1, qtevte,
          evol_tx_mg
        } = article
        acc.push([grv, cdm, ftv, n, cdref, cdrefpre, formatCsvNumber(pxosc),
          pxbasecat, formatCsvNumber(pxbase), formatCsvNumber(pxt6), formatCsvNumber(pxt6manual),
          formatCsvPercentNumber(txrm, 3), formatCsvPercentNumber(txmgt6, 3), formatCsvPercentNumber(txrmppv),
          formatCsvPercentNumber(txdct), formatCsvNumber(pxt6m1), formatCsvPercentNumber(txrmm1),
          formatCsvPercentNumber(txmgt6m1), formatCsvPercentNumber(txrmppvm1), formatCsvNumber(qtevte),
          formatCsvNumber(evol_tx_mg)
        ]
          .join(';'))
        return acc
      }, [])
    }

    const onGrvItemChanged = (selectedGrv: string | null): void => {
      this.setState({ selectedGrv })
    }

    const onFtvItemChanged = (item: string | null): void => {
      this.setState({ selectedFtv: item })
    }

    const onCodeMarqueItemChanged = (item: string | null): void => {
      this.setState({ selectedMarque: item })
    }

    return (
      <Fabric className="report-page">
        <PreconisationMenuItems CategorieName={this.props.CategorieName} SubPageName="pricingremiselineaire" />
        <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
          <div className="ms-Grid header">
            <Header items={this.headerItems} />
          </div>
        </div>
        <div className="ms-Grid"></div>
        <div style={{ boxShadow: theme.effects.elevation4, paddingLeft: 10, paddingBottom: 10, paddingRight: 10 }}>
          <h2>Paramétrage</h2>
          <p>Veuillez créer 1 ou plusieurs paramétrage(s) afin de lancer le calcul</p>
          { this.state.editedParameter !== null && <MessageBarBasic messageBarType={MessageBarType.info} description={`Viewing ${this.state.editedParameter.name ?? ''} parameter`} /> }
          <div className="ms-Grid">
          {this.state.isBusy &&
              (
              <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                <Spinner label="chargement des données de paramétrages .." ariaLive="assertive" labelPosition="left" />
              </div>
              )
          }

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg10" >
                <Stack horizontal wrap tokens={stackTokens}>
                  <Stack.Item grow={6}>
                    <GenericPricingRemisePicker
                        ref={this.grvPicker}
                        data={codesGrv}
                        title="GRV"
                        onItemChanged={onGrvItemChanged}
                        pickerSuggestionsProps={pickerGrvSuggestionsProps} />
                  </Stack.Item>
                  <Stack.Item grow={2}>
                    <GenericPricingRemisePicker
                        ref={this.marquePicker}
                        data={codesMarque}
                        title="Marque"
                        onItemChanged={onCodeMarqueItemChanged}
                        pickerSuggestionsProps={pickerMarqueSuggestionsProps} />
                  </Stack.Item>
                  <Stack.Item grow={2}>
                    <GenericPricingRemisePicker
                        ref={this.ftvPicker}
                        data={codesFtv}
                        title="FTV"
                        onItemChanged={onFtvItemChanged}
                        pickerSuggestionsProps={pickerFtvSuggestionsProps} />
                  </Stack.Item>
                </Stack>
              </div>

            </div>
                <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                  <div className="ms-Grid-col">
                    <label className="ms-fontWeight-semibold" >Type tarif</label>
                    <Dropdown
                            defaultSelectedKey="T3"
                            selectedKey={this.state.selectedTarifType}
                            options={tarifOptions}
                            onChange={onTarifTypeChanged}
                            styles={dropdownStyles}
                        />
                  </div>
                  <div className="ms-Grid-col">
                    <label className="ms-fontWeight-semibold">Modification tarif de base</label>
                        <TextField type="number"
                            className={pricingClassNames.inputNumberRight}
                            inputClassName={pricingClassNames.alignRight}
                            onChange={onCoefBaseValueChanged}
                            value={this.state.selectedCoefBase != null ? this.state.selectedCoefBase.toString() : '0'} />
                  </div>
                  <div className="ms-Grid-col">
                    <label className="ms-fontWeight-semibold" >% ou €</label>
                        <Dropdown
                            defaultSelectedKey="%"
                            selectedKey={this.state.selectedCoefBaseType}
                            options={coefOptions}
                            onChange={onCoefBaseCatChanged}
                            styles={dropdownStyles}
                        />
                  </div>
                  <div className="ms-Grid-col">
                    <label className="ms-fontWeight-semibold">Remise</label>
                        <TextField type="number"
                            className={pricingClassNames.inputNumberRight}
                            inputClassName={pricingClassNames.alignRight}
                            onChange={onCoefValueChanged}
                            value={this.state.selectedCoef != null ? this.state.selectedCoef.toString() : '0'} />
                  </div>
                  <div className="ms-Grid-col">
                    <label className="ms-fontWeight-semibold" >Taux remise cat.</label>
                        <Dropdown
                            defaultSelectedKey="%"
                            selectedKey={this.state.selectedCoefType}
                            options={coefPercentOptions}
                            onChange={onCoefTypeChanged}
                            styles={dropdownStyles}
                        />
                  </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                  <div className="ms-Grid-col ms-sm12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <PrimaryButton text="Ajouter" onClick={_addFilter} disabled={this.state.isBusy}/>
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12" >
                    <Separator alignContent="start"></Separator>
                  </div>
                </div>
                <PricingRemiseLineaireDetails
                      data={this.state.pricingSettingsRowsData}
                      OnCalculate={_calculate}
                      OnDeleteItem={value => _onDeleteIconButtonClicked(value)}
                      editedParameter={this.state.editedParameter}
                      isReadonly={false}
                      isDisabled={this.state.isBusy}/>
            </div>
        </div>

        {this.state.articles?.length > 0 &&
        (
            <PricingRemiseArticlesResult
                articles={this.state.articles}
                articlesColumnsDefs={this.state.articlesColumnsDefs}
                csvHeaders={csvHeaders}
                csvFileName={csvFileName}
                getArticlesAsCsvFormats={getArticlesAsCsvFormats}
                isDisabled={this.state.isBusy}
                onUploadClicked={onUploadClicked}
            />
        )}
        {this.state.shouldShowUploadPopup && <SavePresetDialog onSave={onUploadConfirmed} onClose={() => this.setState({ shouldShowUploadPopup: false })} titleText='Choose snapshot name' />}
      </Fabric>
    )
  }
}
