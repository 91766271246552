import React from 'react'

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const Autodistribution = React.lazy(async () => await import('./themes/Autodistribution'))
const Doyen = React.lazy(async () => await import('./themes/Doyen'))
const envconfig = require('./envconfig.json')

const ThemeSelector = ({ children }) => (

  <>
    {/* Conditionally render theme, based on the current client context */}
    <React.Suspense fallback={() => null}>
      {(envconfig.selectedTheme.trim() === '' || envconfig.selectedTheme === 'autodistribution') && <Autodistribution />}
      {envconfig.selectedTheme === 'doyen' && <Doyen />}
    </React.Suspense>
    {/* Render children immediately! */}
    {children}
  </>
)

export default ThemeSelector
