import { IBasePickerSuggestionsProps, IDropdownOption, ITag, mergeStyleSets } from '@fluentui/react'
import { IColumn } from '@fluentui/react/lib/DetailsList'

export const pricingClassNames = mergeStyleSets({
  // fileIconHeaderIcon: {
  //   padding: 0,
  //   fontSize: '16px',
  // },
  defaultCell: {
    textAlign: 'left',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle'
      }
    }
  },
  defaultRightCell: {
    textAlign: 'right',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle'
      }
    }
  },
  inputNumberRight: {
    textAlign: 'right',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  alignRight: {
    textAlign: 'right'
  }
  /* fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  }, */
})

export const formatCsvNumber = (number: number) => {
  if (number === undefined) { return }
  return number.toFixed(2).replace(/\./g, ',')
}

export const formatCsvPercentNumber = (number: number, nbDecimal: number = 2) => {
  if (number === undefined) { return }
  return (number / 100).toFixed(nbDecimal).replace(/\./g, ',')
}

export const defaultSettingsColumns: IColumn[] = [
  { key: 'col1', name: 'Grv', className: pricingClassNames.defaultCell, fieldName: 'grv', minWidth: 310, isResizable: false },
  { key: 'col2', name: 'Marque', className: pricingClassNames.defaultCell, fieldName: 'cdm', minWidth: 50, maxWidth: 50, isResizable: false },
  { key: 'col3', name: 'Ftv', className: pricingClassNames.defaultCell, fieldName: 'ftv', minWidth: 100, maxWidth: 100, isResizable: false },
  { key: 'col4', name: 'Type Tarif', className: pricingClassNames.defaultCell, fieldName: 'tdb', minWidth: 80, maxWidth: 80, isResizable: false }
]

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob(['\ufeff' + data], { type: fileType })

  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

export function getCurrentDate (separator = '') {
  const newDate = new Date()
  const date = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export const tarifOptions: IDropdownOption[] = [
  { key: 'T1', text: 'T1' },
  { key: 'T2', text: 'T2' },
  { key: 'T3', text: 'T3' },
  { key: 'T4', text: 'T4' },
  { key: 'T5', text: 'T5' },
  { key: 'T6', text: 'T6' }
]

export const coefOptions: IDropdownOption[] = [
  { key: 'coef', text: '€' },
  { key: '%', text: '%' }
]

export const coefPercentOptions: IDropdownOption[] = [
  { key: '%', text: '%' }
]

export const pricingTypeOptions: IDropdownOption[] = [
  { key: '%', text: '%' },
  { key: '€', text: '€' }
]

export const pickerGrvSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Liste des codes GRV',
  noResultsFoundText: 'Pas de codes GRV correspondant.'
}

export const pickerFtvSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Liste des codes FTV',
  noResultsFoundText: 'Pas de codes FTV correspondant.'
}

export const pickerMarqueSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Liste des codes Marques',
  noResultsFoundText: 'Pas de codes FTV correspondant.'
}

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (tagList === undefined || tagList === null) return false

  if (!tagList || (tagList.length === 0) || tagList.length === 0) {
    return false
  }
  return tagList.some(compareTag => compareTag.key === tag.key)
}
