import { PowerBIEmbed } from 'powerbi-client-react'
import React from 'react'
import {
  Accordion
  , AccordionItem
  , AccordionItemHeading
  , AccordionItemButton
  , AccordionItemPanel
} from 'react-accessible-accordion'
import Play from '../../medias/play.svg'

interface IPricingReportTypeZero {
  id: string
  embedUrl: string
  accessToken: string
  rapportDescription: string
}

export default class PricingReportTypeZero extends React.Component<IPricingReportTypeZero, any> {
  handleAccordionClick () {
    const playImage = document.getElementById('play-image')
    if (playImage?.classList.contains('afficher')) { document.getElementById('play-image')?.classList.remove('afficher') } else { document.getElementById('play-image')?.classList.add('afficher') }
  };

  public render () {
    return (
            <>
                <Accordion allowZeroExpanded className="accordion-actualite">
                    <AccordionItem onClick={this.handleAccordionClick}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2><img alt="Rapport" className="report-play-icon" id="play-image" src={Play} width="10px" /> Information</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className="report-description">{this.props.rapportDescription}</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>

                <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg12 report-container">
                    <PowerBIEmbed
                        embedConfig={{
                          type: 'report',
                          id: this.props.id,
                          embedUrl: this.props.embedUrl,
                          accessToken: this.props.accessToken,
                          tokenType: 1,
                          settings: {
                            filterPaneEnabled: false,
                            navContentPaneEnabled: false
                          }
                        }}
                    />
                </div>
            </>
    )
  }
}
