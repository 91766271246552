import * as React from 'react'
import Header from '../Header'
import Menu from '../Menu'
import ProfilePicture from '../../medias/profile-user.svg'
import { authContext } from '../../adalConfig'
import {
  Fabric,
  PrimaryButton
} from '@fluentui/react'

export default class Profile extends React.Component<any> {
  headerItems = {
    title: 'Profil / ',
    subtitle: 'Profil utilisateur'
  }

  menuItems = {
    isActive: true,
    page: 'Profile',
    subPage: '',
    isOpen: false,
    subMenuList: ''
  }

  render () {
    const listeDesRoles = JSON.parse(localStorage.getItem('listeRoles') || '[]')

    return (
            <Fabric>
                <div className="main-menu">
                    <Menu items={this.menuItems} />
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    <div className="ms-Grid header">
                        <Header items={this.headerItems} />
                    </div>
                </div>
                <div className="ms-Grid actualite-content-page" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-row accueil-page-feed ms-sm8 ms-lg8">
                            <div className="feed-title">
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                    <img alt="Profil" className="feed-title-icon" src={ProfilePicture} width="25px" />
                                </div>
                                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                                    <b className="feed-title">Profil Utilisateur</b>
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ul className="feed-content">
                                    <li><b>Prénom et Nom :</b> { authContext.getCachedUser().profile.name }</li>
                                    <li><b>Adresse Email :</b> { authContext.getCachedUser().userName }</li>
                                    <li><b>Identifiant :</b> { authContext.getCachedUser().profile.oid }</li>
                                    <li><b>Rôle(s) :</b> {listeDesRoles.length > 0 ? (<ul className="liste-rapports">{ listeDesRoles.map((role) => <li key={role.libelle}>{role.libelle.replace('_', ' ')}</li>) }</ul>) : (<span> Aucun rôle n'a été trouvé</span>) }</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                            <PrimaryButton onClick={() => { authContext.logOut(); window.localStorage.clear() }} className="profile-retour-btn">
                                Se deconnecter
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Fabric>
    )
  }
}
