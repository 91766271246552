import * as React from 'react'
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { getTheme, mergeStyleSets } from 'office-ui-fabric-react'

export interface OptionDialogProps {
  title: string
  description?: string
  isBusy: boolean
  onDismiss: () => void
  onDelete: () => void
}

const theme = getTheme()
const { palette, fonts } = theme

const modalPropsStyles = { main: { maxWidth: 450 } }
const classNames = mergeStyleSets({
  description: {
    fontSize: fonts.medium.fontSize,
    color: palette.black,
    marginBottom: 10
  }
})

export const OptionDialog: React.FunctionComponent<OptionDialogProps> = props => {
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles
    }),
    [])

  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={() => !props.isBusy && props.onDismiss()}
        dialogContentProps={{ title: props.title }}
        modalProps={modalProps}
      >
        { props.description !== null && <div className={classNames.description}>{props.description}</div> }
        <DialogFooter>
          <PrimaryButton onClick={() => props.onDelete()} text="Delete" disabled={props.isBusy}/>
          <DefaultButton onClick={() => props.onDismiss()} text="Dismiss" disabled={props.isBusy}/>
        </DialogFooter>
      </Dialog>
    </>
  )
}
