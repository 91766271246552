import * as React from 'react'
import Menu from '../Menu'
import { Link } from 'react-router-dom'
import { adalApiFetch } from '../../adalConfig'

export interface IPreconisationMenuItemsProps {
  CategorieName: string
  SubPageName: string
}

export const PreconisationMenuItems: React.FunctionComponent<IPreconisationMenuItemsProps> = props => {
  const [menuItems, setMenuItems] = React.useState<any[]>([])
  const [categoryId, setCategoryId] = React.useState<number | null>(null)
  const [isPanelOpen, setIsPanelOpen] = React.useState(true)

  const subMenu = menuItems.map((rapport) => <Link to={'/preconisation/' + rapport.id}><li className="" key={rapport.id}>{rapport.nom}</li></Link>)
  let realSubMenus: any = ''

  const pricingT6 =
      <>
          <Link to='/pricingremiselineaire'>
          { props.SubPageName === 'pricingremiselineaire' ? <li className="active-item">REMISE LINEAIRE</li> : <li>REMISE LINEAIRE</li>}
          </Link>
          <Link to='/pricingremiselineaireparameters'>
          { props.SubPageName === 'pricingremiselineaireparameters' ? <li className="active-item">REMISE LINEAIRE PARAMETERS</li> : <li>REMISE LINEAIRE PARAMETERS</li>}
          </Link>
          <Link to='/pricingremisearticle'>
          { props.SubPageName === 'pricingremisearticle' ? <li className="active-item">REMISE ARTICLE</li> : <li>REMISE ARTICLE</li>}
          </Link>
          <Link to='/pricingremisearticleparameters'>
          { props.SubPageName === 'pricingremisearticleparameters' ? <li className="active-item">REMISE ARTICLE PARAMETERS</li> : <li>REMISE ARTICLE PARAMETERS</li>}
          </Link>
      </>

  const listeDesRoles = JSON.parse(localStorage.getItem('listeRoles') || '[]')
  const listeDesLibelles = listeDesRoles.map((role) => role.libelle)
  realSubMenus =
      <ul>
          {subMenu.reverse()}
          {(listeDesLibelles.includes('SUPER_ADMIN') || listeDesLibelles.includes('PRICING_T6')) ? pricingT6 : ''}
      </ul>

  const mainItems = {
    isActive: true,
    page: 'preconisation',
    titreMenu: 'Préconisation',
    subPage: props.SubPageName,
    isOpen: isPanelOpen,
    subMenuList: realSubMenus
  }

  React.useEffect(() => {
    adalApiFetch(fetch, '/GetCategorieByName', {
      headers: { CategorieName: props.CategorieName },
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        setCategoryId(responseJson.id)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  React.useEffect(() => {
    if (categoryId === null || categoryId === undefined) {
      return
    }
    adalApiFetch(fetch, '/GetAuthorizedReports', {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('apiToken'),
        UtilisateurId: window.localStorage.getItem('userId'),
        CategorieId: categoryId,
        'Content-Type': 'application/json'
      },
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        setMenuItems(responseJson)
        localStorage.setItem('reportsMenuItems', JSON.stringify(responseJson))
        try {
          (() => {
            const closeButton = document.getElementsByClassName('ms-Panel-closeButton')[0]
            closeButton.addEventListener('click', (e: Event) => setIsPanelOpen(false))
          })()
        } catch (error) {
          console.error(error)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [categoryId])

  return (
    <div className="main-menu">
        <Menu items={mainItems} />
    </div>
  )
}
