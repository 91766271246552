import { ITag, TagPicker } from '@fluentui/react'
import { off } from 'process'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { listContainsTagList } from './pricingRemiseConfig'

interface IGenericPricingRemisePickerState {
  id: string
  selectedItem: ITag | null
  selectedItems: ITag[]
}

class GenericPricingRemisePicker extends React.Component<any, IGenericPricingRemisePickerState> {
  constructor (props: any) {
    super(props)
    this.state = {
      id: uuidv4(),
      selectedItem: null,
      selectedItems: []
    }
  }

  _filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
    if (tagList === undefined || tagList === null) return []
    if (this.props.data === undefined || this.props.data === null) return []

    return filterText
      ? this.props.data.filter(
        tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList)
      )
      : []
  }

  _getTextFromItem = (item: ITag) => item.name

  _onItemSelected = (selectedItem?: ITag): ITag | PromiseLike<ITag> | null => {
    if (selectedItem != null || selectedItem !== undefined) {
      this.onItemChanged(selectedItem?.name)
      return selectedItem
    }
    this.onItemChanged(null)
    this.setState({ selectedItems: [] })
    return null
  }

  onItemChanged = (selectedItem: string | null) => {
    this.props.onItemChanged(selectedItem)
  }

  onSelectedChanged = (items: ITag[] | undefined) => {
    if (items === undefined || items.length === 0) {
      this.onItemChanged(null)
      this.setState({ selectedItems: [] })
      return []
    }
    this.setState({ selectedItems: items })
    return items
  }

  resetSelectedItems () {
    this.setState({ selectedItems: [] })
  }

  render () {
    return (
            <React.Fragment>
                <div>
                    <label
                        className="ms-fontWeight-semibold"
                        htmlFor={this.state.id}>{this.props.title}</label>
                    <TagPicker
                        removeButtonAriaLabel="Remove"
                        pickerSuggestionsProps={this.props.pickerSuggestionsProps}
                        itemLimit={1}
                        onResolveSuggestions={this._filterSuggestedTags}
                        getTextFromItem={this._getTextFromItem}
                        pickerCalloutProps={{ doNotLayer: false }}
                        inputProps={{
                          id: this.state.id
                        }}
                        selectedItems={this.state.selectedItems}
                        onChange={this.onSelectedChanged}
                        onItemSelected={this._onItemSelected}
                    />
                </div>
            </React.Fragment>
    )
  }
}

export default GenericPricingRemisePicker
