import { DetailsList, IconButton, IColumn, PrimaryButton, SelectionMode, Spinner, DetailsListLayoutMode } from 'office-ui-fabric-react'
import * as React from 'react'
import { adalApiFetch } from '../../adalConfig'
import { defaultSettingsColumns, pricingClassNames } from '../pricing/pricingRemiseConfig'
import { IArticlePricingLineaire, ISettingPricingLineaire } from '../pricing/pricingRemiseLineaire'
import { RemiseLineaireParameters } from '../pricing/pricingRemiseLineaireParameters'
import { EditPresetDialog } from './editPresetDialog'
import { SavePresetDialog } from './savePresetDialog'

export interface IPricingRemiseLineaireDetails {
  editedParameter?: RemiseLineaireParameters | null
  data: ISettingPricingLineaire[]
  isReadonly: boolean
  OnCalculate?: () => void
  OnDeleteItem?: (value: IArticlePricingLineaire) => void
  isDisabled: boolean
}

export const PricingRemiseLineaireDetails: React.FunctionComponent<IPricingRemiseLineaireDetails> = props => {
  const [isBusy, setIsBusy] = React.useState(false)
  const [shouldShowSavePresetDialog, setShouldShowSavePresetDialog] = React.useState(false)
  const [shouldShowEditPresetDialog, setShouldShowEditPresetDialog] = React.useState(false)
  const [readonlyDefinitions] = React.useState([
    ...defaultSettingsColumns,
    { key: 'col5', name: 'Modification tarif de base', className: pricingClassNames.defaultRightCell, fieldName: 'displayedCoefBase', minWidth: 120, maxWidth: 120, isResizable: false },
    { key: 'col6', name: 'Remise', className: pricingClassNames.defaultRightCell, fieldName: 'displayedCoef', minWidth: 100, maxWidth: 100, isResizable: false }
  ])

  const [definitions] = React.useState([
    ...readonlyDefinitions,
    { key: 'action', name: 'Action', className: pricingClassNames.defaultCell, minWidth: 100, maxWidth: 100, isResizable: false }
  ])

  const onSaveClicked = (): void => {
    if (props.data.length === 0) {
      alert('Ajouter un paramétrage pour procéder au sauvegarder.')
      return
    }
    setShouldShowSavePresetDialog(true)
  }

  const onSavePopupClicked = async (value: string): Promise<void> => {
    setShouldShowSavePresetDialog(false)
    setIsBusy(true)

    await adalApiFetch(fetch, '/pricing-remise/remise-lineaire/parameter', {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('apiToken'),
        UtilisateurId: window.localStorage.getItem('userId'),
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify({ name: value, list: props.data })
    })
      .catch(error => {
        alert('Saving failed, please try again later.')
        console.error(error)
      })

    setIsBusy(false)
  }

  const onEditClicked = (): void => {
    if (props.data.length === 0) {
      alert('Ajouter un paramétrage pour procéder au sauvegarder.')
      return
    }
    setShouldShowEditPresetDialog(true)
  }

  const onEditPopupClicked = async (): Promise<void> => {
    setShouldShowEditPresetDialog(false)
    if (props.editedParameter === null || props.editedParameter === undefined) {
      return
    }
    setIsBusy(true)
    await adalApiFetch(fetch, `/pricing-remise/remise-lineaire/parameter/${props.editedParameter.id ?? ''}/update`, {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('apiToken'),
        UtilisateurId: window.localStorage.getItem('userId'),
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify({ name: props.editedParameter.name, list: props.data })
    })
      .catch(error => {
        alert('Saving failed, please try again later.')
        console.error(error)
      })
    setIsBusy(false)
  }

  const _renderSettingsItemColumn = (item: IArticlePricingLineaire, index?: number, column?: IColumn): React.ReactNode => {
    const fieldContent = item[column!.fieldName as keyof IArticlePricingLineaire] as string
    switch (column!.key) {
      case 'action':
        return <IconButton onClick={() => props.OnDeleteItem!(item)} iconProps={{ iconName: 'Delete' }} title="Supprimer" ariaLabel="Delete" />
      default:
        return <span>{fieldContent}</span>
    }
  }

  return (
    <>
        {props.data?.length > 0 && (
            <React.Fragment>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col" style={{ width: '100%' }}>
                        <DetailsList
                            compact={true}
                            columns={props.isReadonly ? readonlyDefinitions : definitions}
                            selectionMode={SelectionMode.none}
                            onRenderItemColumn={_renderSettingsItemColumn}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            items={props.data} />
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                    { !props.isReadonly &&
                        <div className="ms-Grid-col ms-sm12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {props.editedParameter === null || props.editedParameter === undefined
                              ? <PrimaryButton
                                text="Sauvegarder"
                                disabled={isBusy || props.isDisabled}
                                onClick={onSaveClicked}
                                style={{ marginRight: 10 }} />
                              : <PrimaryButton
                                text="Save edit"
                                disabled={isBusy || props.isDisabled}
                                onClick={onEditClicked}
                                style={{ marginRight: 10 }} /> }
                            <PrimaryButton
                                text="Calculer"
                                disabled={isBusy || props.isDisabled}
                                onClick={props.OnCalculate} />
                        </div>
                    }
                </div>
                { isBusy &&
                    <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                        <Spinner label="Recherche des articles correspondants ..." ariaLive="assertive" labelPosition="left" />
                    </div>
                }
            </React.Fragment>
        )}
        {!props.isReadonly && shouldShowSavePresetDialog && <SavePresetDialog onSave={onSavePopupClicked} onClose={() => setShouldShowSavePresetDialog(false)} titleText='Choose preset name' />}
        {!props.isReadonly && shouldShowEditPresetDialog && <EditPresetDialog onEdit={onEditPopupClicked} onClose={() => setShouldShowEditPresetDialog(false)} />}
    </>
  )
}
