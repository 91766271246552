import React from 'react'
import logo_autodistribution from '../medias/logo.jpg'
import logo_doyen from '../medias/logo_doyen.jpg'

interface Props{
  items: any
}

const envconfig = require('../envconfig.json')
let logo = logo_autodistribution

switch (envconfig.selectedTheme) {
  case 'autodistribution':
    logo = logo_autodistribution
    break
  case 'doyen':
    logo = logo_doyen
    break
  default:
    logo = logo_autodistribution
    break
}

function Header (props: Props) {
  return (
		<div className="ms-Grid" dir="ltr">
			<div className="ms-Grid-row content" >
				<div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
					<div className="header-element">
						<p className="header-title">{props.items.title} {props.items.subtitle}</p>
					</div>
				</div>
				<div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 header-logo-container align-right">
					<img alt="Logo Autodistribution" src={logo} className="header-logo"></img>
				</div>
			</div>
		</div>
  )
}

export default Header
