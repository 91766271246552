import * as React from 'react'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { TextField } from 'office-ui-fabric-react'

export interface ISavePresetDialog {
  onSave: (value: string) => void
  onClose: () => void
  titleText: string
  buttonText?: string
}

const modalPropsStyles = { main: { maxWidth: 450 } }
const dialogContentProps = {
  onClick: DialogType.normal,
  title: 'Choose preset name'
}

export const SavePresetDialog: React.FunctionComponent<ISavePresetDialog> = props => {
  const [textValue, setTextValue] = React.useState('')
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles
    }),
    [])

  const onTextChanged = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setTextValue(newValue != null ? newValue : '')
    },
    []
  )

  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={() => props.onClose()}
        dialogContentProps={{ title: props.titleText }}
        modalProps={modalProps}
      >
        <TextField required maxLength={255} onChange={onTextChanged}/>
        <DialogFooter>
          <PrimaryButton onClick={() => props.onSave(textValue)} text={props.buttonText ?? 'Save'} disabled={textValue === '' || textValue === undefined || textValue === null}/>
          <DefaultButton onClick={() => props.onClose()} text="Dismiss" />
        </DialogFooter>
      </Dialog>
    </>
  )
}
