import { DetailsList, IconButton, IColumn, PrimaryButton, SelectionMode, Spinner } from 'office-ui-fabric-react'
import * as React from 'react'
import { adalApiFetch } from '../../adalConfig'
import { ISettingPricingArticle, IArticlePricingArticle } from '../pricing/pricingRemiseArticle'
import { RemiseArticleParameters } from '../pricing/pricingRemiseArticleParameters'
import { defaultSettingsColumns, pricingClassNames } from '../pricing/pricingRemiseConfig'
import { EditPresetDialog } from './editPresetDialog'
import { SavePresetDialog } from './savePresetDialog'

export interface IPricingRemiseArticleDetailsProps {
  editedParameter?: RemiseArticleParameters | null
  data: ISettingPricingArticle[]
  isReadonly: boolean
  OnCalculate?: () => void
  OnDeleteItem?: (value: IArticlePricingArticle) => void
  isDisabled: boolean
}

export const PricingRemiseArticleDetails: React.FunctionComponent<IPricingRemiseArticleDetailsProps> = props => {
  const [isBusy, setIsBusy] = React.useState(false)
  const [shouldShowSavePresetDialog, setShouldShowSavePresetDialog] = React.useState(false)
  const [shouldShowEditPresetDialog, setShouldShowEditPresetDialog] = React.useState(false)
  const [readonlyDefinitions] = React.useState([
    ...defaultSettingsColumns,
    { key: 'col5', name: 'Top 25 %', className: pricingClassNames.defaultRightCell, fieldName: 'displayedTx25', minWidth: 60, maxWidth: 60, isResizable: true },
    { key: 'col6', name: '25 % - 60 %', className: pricingClassNames.defaultRightCell, fieldName: 'displayedTx25_60', minWidth: 60, maxWidth: 60, isResizable: true },
    { key: 'col7', name: '> 60 %', className: pricingClassNames.defaultRightCell, fieldName: 'displayedTx60', minWidth: 60, maxWidth: 60, isResizable: true },
    { key: 'col8', name: 'Remise palier (%)', className: pricingClassNames.defaultRightCell, fieldName: 'rp', minWidth: 60, maxWidth: 60, isResizable: true },
    { key: 'col9', name: 'Coeficient de base', className: pricingClassNames.defaultRightCell, fieldName: 'displayedCoefBase', minWidth: 120, maxWidth: 120, isResizable: false }
  ])
  const [definitions] = React.useState([
    ...readonlyDefinitions,
    { key: 'action', name: 'Action', minWidth: 80, maxWidth: 80, isResizable: false }
  ])

  const onSaveClicked = (): void => {
    if (props.data.length === 0) {
      alert('Ajouter un paramétrage pour procéder au sauvegarder.')
      return
    }
    setShouldShowSavePresetDialog(true)
  }

  const onSavePopupClicked = async (value: string): Promise<void> => {
    setShouldShowSavePresetDialog(false)
    setIsBusy(true)

    await adalApiFetch(fetch, '/pricing-remise/remise-article/parameter', {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('apiToken'),
        UtilisateurId: window.localStorage.getItem('userId'),
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify({ name: value, list: props.data })
    })
      .catch(error => {
        alert('Saving failed, please try again later.')
        console.error(error)
      })

    setIsBusy(false)
  }

  const onEditClicked = (): void => {
    if (props.data.length === 0) {
      alert('Ajouter un paramétrage pour procéder au sauvegarder.')
      return
    }
    setShouldShowEditPresetDialog(true)
  }

  const onEditPopupClicked = async (): Promise<void> => {
    setShouldShowEditPresetDialog(false)
    if (props.editedParameter === null || props.editedParameter === undefined) {
      return
    }
    setIsBusy(true)
    await adalApiFetch(fetch, `/pricing-remise/remise-article/parameter/${props.editedParameter.id ?? ''}/update`, {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('apiToken'),
        UtilisateurId: window.localStorage.getItem('userId'),
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify({ name: props.editedParameter.name, list: props.data })
    })
      .catch(error => {
        alert('Saving failed, please try again later.')
        console.error(error)
      })
    setIsBusy(false)
  }

  const _renderSettingsItemColumn = (item: IArticlePricingArticle, index?: number, column?: IColumn): React.ReactNode => {
    const fieldContent = item[column!.fieldName as keyof IArticlePricingArticle] as string
    switch (column!.key) {
      case 'action':
        return <IconButton onClick={() => props.OnDeleteItem!(item)} iconProps={{ iconName: 'Delete' }} title="Supprimer" ariaLabel="Delete" />
      default:
        return <span>{fieldContent}</span>
    }
  }

  return (
    <>
        {props.data?.length > 0 && (
            <React.Fragment>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col" style={{ width: '100%' }}>
                        <DetailsList
                            compact={true}
                            columns={props.isReadonly ? readonlyDefinitions : definitions}
                            selectionMode={SelectionMode.none}
                            onRenderItemColumn={_renderSettingsItemColumn}
                            items={props.data} />
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                    { !props.isReadonly &&
                        <div className="ms-Grid-col ms-sm12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {props.editedParameter === null || props.editedParameter === undefined
                              ? <PrimaryButton
                                text="Sauvegarder"
                                disabled={isBusy || props.isDisabled}
                                onClick={onSaveClicked}
                                style={{ marginRight: 10 }} />
                              : <PrimaryButton
                                text="Save edit"
                                disabled={isBusy || props.isDisabled}
                                onClick={onEditClicked}
                                style={{ marginRight: 10 }} /> }
                            <PrimaryButton
                                text="Calculer"
                                disabled={isBusy || props.isDisabled}
                                onClick={props.OnCalculate} />
                        </div>
                    }
                </div>
                { isBusy &&
                    <div className="ms-Grid-row" style={{ marginTop: 20 }}>
                        <Spinner label="Recherche des articles correspondants ..." ariaLive="assertive" labelPosition="left" />
                    </div>
                }
            </React.Fragment>
        )}
        {!props.isReadonly && shouldShowSavePresetDialog && <SavePresetDialog onSave={onSavePopupClicked} onClose={() => setShouldShowSavePresetDialog(false)} titleText='Choose preset name' />}
        {!props.isReadonly && shouldShowEditPresetDialog && <EditPresetDialog onEdit={onEditPopupClicked} onClose={() => setShouldShowEditPresetDialog(false)} />}
    </>
  )
}
