import Menu from './Menu'
import Header from './Header'
import * as React from 'react'
import { Fabric, Spinner, SpinnerSize } from '@fluentui/react'

export default class LoadinPage extends React.Component<any> {
  headerItems = {
    title: 'Chargement en cours ...',
    subtitle: ''
  }

  menuItems = {
    isActive: true,
    page: '',
    subMenuList: '',
    isOpen: false
  }

  render () {
    return (
            <Fabric className="accueil-page">
                <div className="main-menu">
                    <Menu items={this.menuItems} />
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    <div className="ms-Grid header">
                        <Header items={this.headerItems} />
                    </div>
                </div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row accueil-top-content">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                            <Spinner size={SpinnerSize.large} />
                        </div>
                    </div>
                </div>
            </Fabric>
    )
  }
}
