import { ConstrainMode, DetailsList, DetailsListLayoutMode, DetailsRow, getTheme, IColumn, IDetailsListProps, IDetailsListStyles, IDetailsRowStyles, mergeStyleSets, PrimaryButton, SelectionMode, Separator, TagPicker } from '@fluentui/react'
import React, { ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { downloadFile } from './pricingRemiseConfig'

const theme = getTheme()

const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowX: 'scroll',
    selectors: {
      '& [role=grid]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: '60vh'
      }
    }
  },
  headerWrapper: {
    flex: '0 0 auto'
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
}
interface IPricingRemiseArticlesResultState {
  id: string
}

class PricingRemiseArticlesResult extends React.Component<any, IPricingRemiseArticlesResultState> {
  constructor (props: any) {
    super(props)
    this.state = {
      id: uuidv4()
    }
  }

  _onRenderItemColumn = (item: any, index?: number, column?: IColumn): ReactNode => {
    if (this.props.onRenderArticleItemColumn != undefined) { return this.props.onRenderArticleItemColumn(item, index, column) }

    const fieldContent = item[column!.fieldName as typeof item] as string
    if (column!.key.toLowerCase().includes('tx')) {
      const fieldContentNumber = Number(fieldContent)
      return <span>{ fieldContentNumber.toFixed(2)} %</span>
    }
    return <span>{fieldContent}</span>
  }

  _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {}
    if (props != null) {
      if (props.itemIndex % 2 === 0) {
        customStyles.root = { backgroundColor: theme.palette.themeLighterAlt }
      }

      return <DetailsRow {...props} styles={customStyles} />
    }
    return null
  }

  _exportToCsv = e => {
    e.preventDefault()

    const headers = this.props.csvHeaders

    if (!this.props.articles) {
      alert("il n'y a pas d'articles à exporter")
      return
    }
    const articlesCsv = this.props.getArticlesAsCsvFormats()

    downloadFile({
      data: [...headers, ...articlesCsv].join('\n'),
      fileName: this.props.csvFileName,
      fileType: 'text/csv; charset=utf8'
    })
  }

  render () {
    return (
            <React.Fragment>
                <div style={{ boxShadow: theme.effects.elevation4, paddingLeft: 10, paddingBottom: 10, paddingRight: 10 }}>
                    <h2 className="ms-md12">Articles</h2>
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12" >
                                <Separator alignContent="start"></Separator>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col" style={{ height: 600, width: '100%', overflow: 'auto' }}>
                                <DetailsList
                                    compact={true}
                                    onRenderRow={this._onRenderRow}
                                    onRenderItemColumn={this._onRenderItemColumn}
                                    columns={this.props.articlesColumnsDefs}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    styles={gridStyles}
                                    constrainMode={ConstrainMode.unconstrained}
                                    items={this.props.articles}/>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <PrimaryButton text="Upload" onClick={this.props.onUploadClicked} disabled={this.props.isDisabled} style={{ marginRight: 10 }}/>
                                <PrimaryButton text="Exporter" type="submit" onClick={this._exportToCsv} disabled={this.props.isDisabled}/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
    )
  }
}

export default PricingRemiseArticlesResult
