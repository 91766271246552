import { useEffect } from 'react'
import { Router, Route, Switch, useLocation } from 'react-router-dom'
import Accueil from './components/Accueil'
import Erreur from './components/Erreur'
import history from './components/History'
import Role from './components/roles/role'
import Support from './components/support/support'
import Unauthorized from './components/Unauthorized'
import Actualite from './components/actualite/actualite'
import 'office-ui-fabric-react/dist/css/fabric.css'
import './fonts/futura-light-bt.ttf'
import ReactDOM from 'react-dom'
import ActualiteContent from './components/actualite/actualiteContent'
import ConfigurationPowerBi from './components/rapports/powerBI/configurationPowerBi'
import ModifierRapportPowerBi from './components/rapports/powerBI/modifierRapportPowerBi'
import AjouterRapportPowerBi from './components/rapports/powerBI/ajouterRapportPowerBi'

import PricingRemiseArticle from './components/pricing/pricingRemiseArticle'
import PricingRemiseLineaire from './components/pricing/pricingRemiseLineaire'
import { PricingRemiseArticleParameters } from './components/pricing/pricingRemiseArticleParameters'

// import ConfigurationMicroStrategy from './components/rapports/microStrategy/configurationMicroStrategy';
// import ModifierRapportMicroStrategy from './components/rapports/microStrategy/modifierRapportMicroStrategy';
// import AjouterRapportMicroStrategy from './components/rapports/microStrategy/ajouterRapportMicroStrategy';
import Profile from './components/profile/profile'
import LoadinPage from './components/LoadingPage'
import { adalApiFetch, authContext } from './adalConfig'
import Pricing from './components/pricing/pricing'
import ThemeSelector from './ThemeSelector'
import { PricingRemiseLineaireParameters } from './components/pricing/pricingRemiseLineaireParameters'

// import { ModuleRegistry, AllCommunityModules } from '@ag-grid-community/all-modules';
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

// ModuleRegistry.registerModules([
// 	AllCommunityModules,
//     ClientSideRowModelModule,
// ]);

function sendStatistic (reportId: any, start_date: any, end_date: any) {
  // const firstName = (authContext.getCachedUser().profile.name).split(' ')[0];
  const userName = authContext.getCachedUser().userName
  const data = {
    idRapport: reportId,
    nomRapport: '',
    idUser: localStorage.getItem('userId') || '',
    userName,
    startDate: start_date,
    endDate: end_date
  }

  const apiToken = window.localStorage.getItem('apiToken')
  const userId = window.localStorage.getItem('userId')

  adalApiFetch(fetch, '/AddRapportStatistic', {
    headers: {
      Authorization: 'Bearer ' + apiToken,
      UtilisateurId: userId,
      'Content-Type': 'application/json'
    },
    method: 'post',
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.status !== 200) { console.error(response) }
    })
    .catch(error => {
      console.error(error)
    })
}

function checkPageAndSendStatistic (pathname: string, isUnload: boolean) {
  function checkInput (input, words) {
    return words.some(word => input.toLowerCase().includes(word.toLowerCase()))
  }

  if (localStorage.getItem('path') != pathname || isUnload) {
    if (localStorage.getItem('path') && checkInput(localStorage.getItem('path'), ['pricingvl', 'pricingpl', 'preconisation', 'transverse']) && localStorage.getItem('perfnav') != 'navigate') // if last page is a rapport page
    {
      // statistic : get start time & end time
      // let start_timestamp = parseInt(localStorage.getItem("timestamp") || "");
      // let end_timestamp = Date.now(); //get end timestamp

      // statistic : get start date & end date
      const start_date = localStorage.getItem('date_path') || ''
      const end_date = new Date().toJSON() // get end date
      // get report id
      const n = localStorage.getItem('path')?.toString().lastIndexOf('/')
      const result = localStorage.getItem('path')?.toString().substring(n ? n + 1 : 0)
      if (!result?.includes('/')) {
        // send data
        sendStatistic(result, start_date, end_date)
      }
    }
    // set localstorage
    localStorage.setItem('path', pathname)
    localStorage.setItem('perfnav', '')
    localStorage.setItem('date_path', new Date().toJSON()) // save date
    // localStorage.setItem("timestamp", Date.now().toString()); //save timestamp
  }
}

// onClose tab
window.addEventListener('beforeunload', (ev) => {
  // send page statistic
  checkPageAndSendStatistic(window.location.pathname, true)

  // ev.preventDefault();
  // return ev.returnValue = 'Are you sure you want to close?';
})

function App () {
  // detect page change
  const setPathname = (pathname) => {
    checkPageAndSendStatistic(pathname, false)
  }

  // detect reload
  const setPerformanceNavigation = (performanceNavigation) => {
    localStorage.setItem('perfnav', performanceNavigation)
  }

  return (
		<Router history={history}>
			<Routes setPathname={setPathname} setPerformanceNavigation={setPerformanceNavigation} />
		</Router>
  )
}

function checkRoles (roles: string[], component: any) {
  const listeDesRoles = JSON.parse(localStorage.getItem('listeRoles') || '[]')
  const listeDesLibelles = listeDesRoles.map((role: any) => role.libelle)

  let hasRole = false
  if (roles.length > 0) {
    roles.forEach(role => {
      if (listeDesLibelles.includes(role)) { hasRole = true }
    })
  } else { hasRole = true }

  return hasRole ? component : listeDesLibelles.includes('UNDEFINED_AD_GROUPE') ? LoadinPage : listeDesRoles.length > 0 ? Unauthorized : Erreur
}

function Routes (props) {
  const location = useLocation()
  const performanceNavigation = window.performance.getEntriesByType('navigation')[0].entryType

  useEffect(() => {
    props.setPathname(location.pathname)
  }, [location])

  useEffect(() => {
    props.setPerformanceNavigation(performanceNavigation)
  }, [performanceNavigation])

  if (localStorage.getItem('listeRoles') !== null) {
    // FOR DEBUG ONLY =>
    // console.log('listeDesRoles:');
    // console.log(localStorage.getItem('listeRoles'));
    const globalRoles = ['SUPER_ADMIN', 'KEY_USER', 'SIEGE_ALL', 'ENSEIGNE_VL', 'ENSEIGNE_PL', 'SIEGE_VL', 'SIEGE_PL']
    const vlRoles = ['SUPER_ADMIN', 'KEY_USER', 'SIEGE_ALL', 'ENSEIGNE_VL', 'SIEGE_VL']
    const plRoles = ['SUPER_ADMIN', 'KEY_USER', 'SIEGE_ALL', 'ENSEIGNE_VL', 'ENSEIGNE_PL', 'SIEGE_VL', 'SIEGE_PL']
    const siegeRoles = ['SUPER_ADMIN', 'KEY_USER', 'SIEGE_ALL', 'SIEGE_VL', 'SIEGE_PL']
    const adminSiegeRoles = ['SUPER_ADMIN', 'KEY_USER', 'SIEGE_ALL']
    const adminRoles = ['SUPER_ADMIN', 'TEST_EVOSEEDS']
    const pricingT6Roles = ['SUPER_ADMIN', 'PRICING_T6']

    return (
			<div className="App" id="App">
				<div className="ms-Grid" dir="ltr">
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
							<Switch>
								<Route exact path="/erreur" component={Erreur} />
								<Route path="/unauthorized" component={Unauthorized} />

								{/* PROFIL */}
								<Route exact path="/profile" component={checkRoles([], Profile)} />

								{/* ACCUEIL */}
								<Route exact path="/" component={checkRoles(globalRoles, Accueil)} />
								<Route exact path="/Accueil" component={checkRoles(globalRoles, Accueil)} />
								<Route exact path="/actualiteContent" component={checkRoles(globalRoles, ActualiteContent)} />

								{/* PRICING VL */}
								<Route exact path="/pricingvl" render={(props) => checkRoles(vlRoles, <Pricing {...props} CategorieName="PRICING_VL" path="pricingvl" title="Pricing VL" key={'pricingvl'} />)} />
								<Route exact path="/pricingvl/:rapportId" render={(props) => checkRoles(vlRoles, <Pricing {...props} CategorieName="PRICING_VL" path="pricingvl" title="Pricing VL" key={props.match.params.rapportId} />)} />

								{/* PRICING PL */}
								<Route exact path="/pricingpl" render={(props) => checkRoles(plRoles, <Pricing {...props} CategorieName="PRICING_PL" path="pricingpl" title="Pricing PL" key={'pricingpl'} />)} />
								<Route exact path="/pricingpl/:rapportId" render={(props) => checkRoles(plRoles, <Pricing {...props} CategorieName="PRICING_PL" path="pricingpl" title="Pricing PL" key={props.match.params.rapportId} />)} />

								{/* PRECONISATION */}
								<Route exact path="/preconisation" render={(props) => checkRoles(siegeRoles, <Pricing {...props} CategorieName="PRECO_TARIFAIRE" path="preconisation" title="Préconisation" key={'preconisation'} />)} />
								<Route exact path="/preconisation/:rapportId" render={(props) => checkRoles(siegeRoles, <Pricing {...props} CategorieName="PRECO_TARIFAIRE" path="preconisation" title="Préconisation" key={props.match.params.rapportId} />)} />

								{/* PRICING ARTICLE & LINEAIRE */}
								<Route exact path="/pricingremisearticle" render={(props) => checkRoles(pricingT6Roles, <PricingRemiseArticle {...props} CategorieName="PRECO_TARIFAIRE" />)} />
								<Route exact path="/pricingremisearticleparameters" render={(props) => checkRoles(pricingT6Roles, <PricingRemiseArticleParameters {...props} CategorieName="PRECO_TARIFAIRE" />)} />
								<Route exact path="/pricingremiselineaire" render={(props) => checkRoles(pricingT6Roles, <PricingRemiseLineaire {...props} CategorieName="PRECO_TARIFAIRE"/>)} />
								<Route exact path="/pricingremiselineaireparameters" render={(props) => checkRoles(pricingT6Roles, <PricingRemiseLineaireParameters {...props} CategorieName="PRECO_TARIFAIRE" />)} />

								{/* TRANSVERSE */}
								<Route exact path="/transverse" render={(props) => checkRoles(adminSiegeRoles, <Pricing {...props} CategorieName="TRANSVERSE" path="transverse" title="Transverse" key={'transverse'} />)} />
								<Route exact path="/transverse/:rapportId" render={(props) => checkRoles(adminSiegeRoles, <Pricing {...props} CategorieName="TRANSVERSE" path="transverse" title="Transverse" key={props.match.params.rapportId} />)} />

								{/* ADMINISTRATION */}
								{/* PowerBI */}
								<Route exact path="/configurationPowerBi" component={checkRoles(adminRoles, ConfigurationPowerBi)} />
								<Route exact path="/modifierRapportPowerBi/:rapportId" component={checkRoles(adminRoles, ModifierRapportPowerBi)} />
								<Route exact path="/ajouterRapportPowerBi" component={checkRoles(adminRoles, AjouterRapportPowerBi)} />
								{/* MicroStrategy */}
								{/* TODO : uncomment to make Microstrategy work  */}
								{/* <Route exact path="/configurationMicroStrategy" component={checkRoles(adminRoles, ConfigurationMicroStrategy)} />
								<Route exact path="/modifierRapportMicroStrategy/:rapportId" component={checkRoles(adminRoles, ModifierRapportMicroStrategy)} />
								<Route exact path="/ajouterRapportMicroStrategy" component={checkRoles(adminRoles, AjouterRapportMicroStrategy)} /> */}
								{/* Others */}
								<Route exact path="/role" component={checkRoles(adminRoles, Role)} />
								<Route exact path="/actualite" component={checkRoles(adminRoles, Actualite)} />
								<Route exact path="/support" component={checkRoles(adminRoles, Support)} />

								{/* L'ordre des composants Route est important, ci-dessous, doit toujours être en dernier */}
								<Route component={Unauthorized} />
							</Switch>
						</div>
					</div>
				</div>
			</div>
    )
  } else {
    return (<></>)
  }
}
ReactDOM.render(<ThemeSelector><App /></ThemeSelector>, document.getElementById('root'))
