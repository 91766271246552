import {
  Fabric
  , IDatePickerStrings
  , IIconProps
  , PrimaryButton
  , TextField
  , mergeStyleSets
  , DatePicker
  , DayOfWeek
  , Toggle
  , MessageBar
  , MessageBarType
  , Spinner
  , SpinnerSize
} from '@fluentui/react'
import * as React from 'react'
import { adalApiFetch } from '../../adalConfig'
import Header from '../Header'
import Menu from '../Menu'

const DayPickerStrings: IDatePickerStrings = {
  months: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],

  shortMonths: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],

  shortDays: ['D', 'L', 'M', 'M', 'J', 'v', 'S'],

  goToToday: 'Ajourd\'hui',
  prevMonthAriaLabel: 'Mois précédent',
  nextMonthAriaLabel: 'Mois suivant',
  prevYearAriaLabel: 'Année précédente',
  nextYearAriaLabel: 'Année suivante',
  closeButtonAriaLabel: 'Fermer',

  isRequiredErrorMessage: 'Ce champ est requis.',

  invalidInputErrorMessage: 'Format de la date non valide.'
}

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px'
  }
})

interface IActualiteConfig {
  id: string
  firstDayOfWeek?: DayOfWeek
  startDate: Date | undefined
  dateRedaction: Date
  doitAfficher: boolean
  contenu: string
  dateCreation: Date
  dateModification: Date
  upserted: boolean
  utilisateurCreation: string
  utilisateurModification: string
  error: boolean
  loaded: boolean
  isPanelOpen: boolean
}

const SuccessExample = () => (
	<MessageBar
		messageBarType={MessageBarType.success}
		isMultiline={false}
	>
	  	Les données ont bien été mis à jour !
	</MessageBar>
)

const ErrorNotification = () => (
	<MessageBar
		messageBarType={MessageBarType.error}
		isMultiline={false}
	>
	  	Une erreur est survenue lors de la récupération / mis à jour des données, veuillez réessayer plus tard !
	</MessageBar>
)

export default class Actualite extends React.Component<any, IActualiteConfig> {
  headerItems = {
    title: 'Paramètres / ',
    subtitle: 'Actualité'
  }

  saveIcon: IIconProps = { iconName: 'Save' }

  constructor (props: any) {
    super(props)

    this.state = {
      id: '',
      dateRedaction: new Date(),
      contenu: '',
      doitAfficher: false,
      dateCreation: new Date(),
      utilisateurCreation: '',
      dateModification: new Date(),
      utilisateurModification: '',
      upserted: false,
      firstDayOfWeek: DayOfWeek.Monday,
      startDate: new Date(Date.now()),
      error: false,
      loaded: false,
      isPanelOpen: true
    }
  }

  async componentDidMount () {
    await adalApiFetch(fetch, '/GetSettingActualite', {
      method: 'get'
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({
          id: responseJson.id,
          dateRedaction: new Date(responseJson.dateRedaction),
          doitAfficher: responseJson.doitAfficher,
          contenu: responseJson.contenu
        })
      })
      .catch(error => {
        this.setState({ error: true, upserted: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })

    try {
      (() => {
        const closeButton = document.getElementsByClassName('ms-Panel-closeButton')[0]
        closeButton.addEventListener('click', (e: Event) => this.OpenPanel())
      })()
    } catch (error) {
      console.error(error)
    }

    this.setState({ loaded: true })
  }

  submitHandler = (event: any) => {
    event.preventDefault()

    const data = {
      id: this.state.id,
      dateRedaction: this.state.dateRedaction,
      contenu: this.state.contenu,
      doitAfficher: this.state.doitAfficher,
      dateCreation: this.state.dateCreation,
      dateModification: this.state.dateModification
    }

    adalApiFetch(fetch, '/UpsertActualite', {
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ upserted: true, error: false })
        setTimeout(() => {
          this.setState({ upserted: false })
        }, 5000)
      })
      .catch(error => {
        this.setState({ error: true, upserted: false })
        setTimeout(() => {
          this.setState({ error: false })
        }, 5000)
        console.error(error)
      })
  }

  OpenPanel () {
    this.setState({ isPanelOpen: false })
  }

  render () {
    const { firstDayOfWeek, dateRedaction, contenu, doitAfficher, loaded, isPanelOpen } = this.state

    const menuItems = {
      isActive: true,
      page: 'Parametre',
      subPage: 'actualite',
      isOpen: isPanelOpen,
      subMenuList: ''
    }

    return (
                <form onSubmit={this.submitHandler}>
                    <Fabric>
                        <div className="main-menu">
                            <Menu items={menuItems} />
                        </div>
                        <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                            <div className="ms-Grid header">
                                <Header items={this.headerItems} />
                            </div>
                        </div>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                {loaded
                                  ? (
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                        <p className="page-content-title less-top-margin"><i>Paramétrez et modifiez les informations rattachées à l'actualité.</i></p>

                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="support-input-row">
                                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                    <label>Date de l'actualité</label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                                    <DatePicker
                                                        placeholder="Sélectionner une date..."
                                                        ariaLabel="Sélectionner une date"
                                                        className={controlClass.control}
                                                        firstDayOfWeek={firstDayOfWeek}
                                                        strings={DayPickerStrings}
                                                        formatDate={this._onFormatDate}
                                                        allowTextInput={false}
                                                        value={dateRedaction}
                                                        onSelectDate={newDate => { this.redactionDateSelected(newDate!) }}
                                                        maxDate={new Date(Date.now())}
                                                    />
                                                </div>
                                            </div>
                                            <div className="support-input-row">
                                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                    <label>Afficher l'actualité</label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                                    <Toggle onText="Oui" offText="Non" checked={doitAfficher} onChange={this.onChangeDoitAfficher}/>
                                                </div>
                                            </div>
                                            <div className="support-input-row">
                                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                    <label>Contenu de l'actualité</label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                                    <TextField
                                                        multiline
                                                        rows={8}
                                                        name="contenu"
                                                        value = {contenu}
                                                        onChange={this.onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-col ms-lg12 support-save">
                                                <PrimaryButton
                                                    text="Enregistrer"
                                                    iconProps={this.saveIcon}
                                                    type="submit"
                                                ></PrimaryButton>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                  : (
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 accueil-page-content">
                                                <p className="page-content-title"><i>Modifiez les accès de vos groupes aux différents rapports existants.</i></p>
                                                <Spinner size={SpinnerSize.large} />
                                            </div>
                                        </div>
                                    </div>
                                    )}
                            </div>
                            <div className="notification-bottom">
                                { this.state.upserted ? <SuccessExample /> : null }
                                { this.state.error ? <ErrorNotification /> : null }
                            </div>
                        </div>
                    </Fabric>
                </form>
    )
  }

  redactionDateSelected (date: Date) {
    this.setState({
      startDate: date,
      dateRedaction: date
    })
  }

  onChangeDoitAfficher = (event: any, value: any) => {
    this.setState({ doitAfficher: value })
  }

  onChangeHandler = (event: any, value: any) => {
    let stateName
    let stateValue

    stateName = event.target.name
    stateValue = value

    this.setState({ ...this.state, [stateName]: stateValue })
  }

  private readonly _onFormatDate = (date: Date | undefined): string => {
    return date!.getDate() + '/' + (date!.getMonth() + 1) + '/' + date!.getFullYear()
  }
}
